import React, { Fragment, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {
	Box,
	Container,
	Button,
	Typography,
	List,
	ListItem,
	ListItemText,
	ListItemIcon
} from '@material-ui/core'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Grid from '@material-ui/core/Grid'
import patientMobile from '../images/patients_mobile.png'
import dispensaryMobile from '../images/dispensary_mobile.png'
import mutedPoster from '../images/flash_muted_poster.png'
import proLogo from '../images/wedaProapplogo.png'
//import proLogo from '../images/ezdoc_logo_white(pro).png';
import defaultLogo from '../images/wedawarupatinet_logo.png'
import appleStoreLogo from '../images/mobile-app-store.png'
import googlePlayLogo from '../images/mobile-google-play.png'
import appleStoreLogo1 from '../images/mobile-app-store1.png'
import googlePlayLogo1 from '../images/mobile-google-play1.png'
import wedawaruNam from '../images/wedawaruNam.png'
import docSignUp from '../images/helloDoctorsSignup.png'

import SignupPostCard from '../components/widgets/SignupPostCard'
import StepupPostCard from './widgets/StepupPostCard'
import BenefitsListCard from '../components/widgets/BenefitsListCard'
import Widgets from 'components/widgets'
import { NAVBAR_ROUTES } from 'constants/NavConstants'
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as dispensaryActions from '../containers/forms/Dispensary/redux/actions'
import * as doctorActions from '../containers/forms/doctor/redux/actions'
import {
    isAndroid,
    isIOS
  } from "react-device-detect";

// bullet: {
//   display: 'inline-block',
//     margin: '0 2px',
//       transform: 'scale(0.8)',
//   },

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,

		[theme.breakpoints.up('lg')]: {
			maxWidth: '1300px'
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		},
		// TODO zoom
		[theme.breakpoints.between('md', 'lg')]: {
			//backgroundColor:'red',
			zoom: '0.87'
		}
	},

	bannerRowBox: {
		backgroundSize: 'cover',
		[theme.breakpoints.only('md')]: {
			backgroundSize: 'cover'
		},
		[theme.breakpoints.only('xs')]: {
			backgroundSize: '65vh'
		}
	},

	topHeaderTitle: {
		width: '100%',
		[theme.breakpoints.down('xs')]: {
			width: '160px',
			letterSpacing: '0px'
		}
	},
	bannerBulletList: {
		paddingBottom: '1em',
		[theme.breakpoints.down('sm')]: {
			marginTop: '10em'
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: '1em'
		}
	},

	topBannerButtonBox: {
		height: '100%',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-end',
		padding: '5em 30em 2em 12.5em',
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
			alignItems: 'center'
		}
	},

	logoBoxIcons: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'row',
			alignItems: 'flex-end',
			paddingBottom: '2em'
			//paddingTop: '-1em'
		}
	},
	iconTop: {
		width: '263px',
		height: 'auto',
		'&.pro': {
			// width: '263px',
			// height: 'auto',
		},
		'&.default': {
			// marginTop: '1.5em',
			//marginBottom: '2.5em',
		},
		[theme.breakpoints.down('sm')]: {
			marginRight: '1em',
			width: '100px',
			margin: '0em 0em',
			'&.pro': {
				width: '125px'
			},
			'&.default': {
				width: '125px',
				marginBottom: '0.5em'
			}
		}
	},

	googleImageLink: {
		marginBottom: theme.spacing(1),
		'&:hover': {
			filter: 'drop-shadow(0 0 0.60rem #BBB)'
		}
	},
	appleImageLink: {
		marginTop: theme.spacing(1),
		'&:hover': {
			filter: 'drop-shadow(0 0 0.60rem #BBB)'
		}
	},

	iconBottom: {
		width: '300px',
		height: 'auto',
		marginBottom: '5em',
		'&.default': {
			//marginTop: '1em',
		},

		[theme.breakpoints.down('sm')]: {
			marginLeft: '1em',
			width: '150px',
			margin: '0em 0em'
		}
	},

	/**
	 * ----------------------------------------------
	 */
	patientLeft: {
		order: 1,
		[theme.breakpoints.up('md')]: {
			//order: 2,
		}
	},

	patientRight: {
		order: 2,
		[theme.breakpoints.up('md')]: {
			//order: 1,
		}
	},

	patientImgHand: {
		paddingTop: '-1em',
		marginLeft: '2em',
		width: '100%',
		height: 'auto',
		[theme.breakpoints.down('md')]: {
			marginLeft: '-8.5em'
		},

		[theme.breakpoints.only('sm')]: {
			marginLeft: '-5em', // 25
			width: '70%'
		}
	},
	wedawaruNam: {
		paddingTop: '0em',
		marginLeft: '0em',
		width: '60%',
		height: 'auto',
		// [theme.breakpoints.down('md')]: {
		// 	marginLeft: '-8.5em'
		// },

		// [theme.breakpoints.only('sm')]: {
		// 	marginLeft: '-5em', // 25
		// 	width: '70%'
		// }
	},

	docSignUp: {
		paddingTop: '2.75em',
		marginLeft: '23em',
		width: '25%',
		height: '72%',
		// [theme.breakpoints.down('md')]: {
		// 	marginLeft: '-8.5em'
		// },

		// [theme.breakpoints.only('sm')]: {
		// 	marginLeft: '-5em', // 25
		// 	width: '70%'
		// }
	},

	mainHeadingBlock: {
		textAlign: 'right',
		letterSpacing: '-0.3px',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
			letterSpacing: '0px',
			margin: '1em 0em'
		}
	},

	/**
	 * ----------------------------------------------
	 */

	dispensaryLeft: {
		order: 2,
		[theme.breakpoints.down('sm')]: {
			order: 1
		}
	},
	dispensaryRight: {
		order: 1,
		[theme.breakpoints.down('sm')]: {
			order: 2
		}
	},

	dispensaryImgHand: {
		//paddingTop: '-1em',
		marginRight: '-3em',
		width: '100%',
		height: 'auto',
		[theme.breakpoints.down('md')]: {
			//marginLeft: '-8.5em',
		},

		[theme.breakpoints.only('sm')]: {
			//marginLeft: '-5em', // 25
			width: '70%'
		}
	},

	signupBottomLeft: {
		height: '212px',
		backgroundColor: '#ccc',
		marginBottom: '0.5em',

		'&:after': {
			borderColor: 'rgba(255, 255, 255, 0.2)',
			borderLeftColor: '#f5f5f5', //f30000
			borderWidth: '107px',
			marginTop: '-107px'
		},

		[theme.breakpoints.between('xs', 'sm')]: {
			height: '300px',
			marginBottom: '-2em'
		},

		[theme.breakpoints.between('md', 'lg')]: {
			'&:after': {
				marginTop: '-108px'
			}
		},
		[theme.breakpoints.only('md')]: {
			'&:after': {
				marginTop: '-118px'
			}
		},
		[theme.breakpoints.down('sm')]: {
			'&:after': {
				display: 'none'
			}
		}
	},

	mainBanner: {
		width: '100%',
		height: 'auto',
		position: 'relative'
	},

	bannerImage: {
		width: '100%',
		height: 'auto',
		position: 'absolute',
		top: '0',
		left: 0,
		flexGrow: 1,
		[theme.breakpoints.up('sm')]: {
			//minHeight: 'calc(100% + 0rem)',
			//minWidth: 'calc(100% + 10rem)'
		}
	},
	bannerInfoBlock: {
		flex: 1,
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		position: 'relative',
		border: '0px dotted blue'
	},
	bulletIcon: {
		transform: 'scale(1)',
		color: '#39cfba'
	}
}))

const Home = props => {
	const classes = useStyles()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const auth = useSelector(state => state.authReducer.loggedIn)

	const { history } = props

	useEffect(() => {
		if (auth) {
			// history.push(NAVBAR_ROUTES.OWNER_PORTAL_WEB)
		}
		// if (isAndroid) {
		// 	window.location.href =
		// 	  "https://play.google.com/store/apps/details?id=com.enqbator.ezdoc";
		//   }else if(isIOS) {
		// 	window.location.href =
		// 	  "https://apps.apple.com/lk/app/ezdoc/id1524014547";
		//   } else{
		// 	window.location.href =
		// 	  "https://ezdoc.lk/#/";
		//   }
	})

	function routeToSignup() {
		history.push(NAVBAR_ROUTES.DESPENSARY_SIGNUP_FORM)
	}

	return (
		<Fragment>
			<Box component='div' className={`${classes.bannerRowBox} banner_row_box row_box`}>
			{/* <Box component='div' className='banner_row_box row_box'> */}
				<Container className={classes.root} maxWidth='lg' disableGutters={false} component='div'>
					<div className='content_box'>
						<Grid container spacing={0} justifyContent='center'>
							<Grid item xs={12}>
								<div className={classes.mainBanner}>
									{/* <img src={banners} alt="banner" className={classes.bannerImage} /> */}
									<div className={classes.bannerInfoBlock}>
										{/* banner top heading text */}
										<div className={`${classes.topHeaderTitle} heading_H6 top_header`}>
										{/* වෙදවරු නම් දෙවිවරු */}
										<img
										className={`${classes.wedawaruNam} patient_img_hand`}
										src={wedawaruNam}
										alt='wedawaru-image'
										align='top'
									/>
										</div>
										{/* banner bottom text */}
										<div className={`${classes.topHeaderTitle} heading_H7 top_header`}>
										Book Appointments for Ayurveda and Indigenous Treatments …
										</div>
										<div className={`${classes.topHeaderTitle} heading_H8 top_header`}>
										Inviting Doctors, Spas, and Dispensaries to signup with us
										</div>
										<div>
											<Box className={classes.topBannerButtonBox}>
												<Widgets.ButtonBox
													type='button'
													disabled={false}
													label='SignUp Now'
													variant='contained'
													color='wedawaruSignup'
													size='large'
													onClick={routeToSignup}
												/>
											</Box>
										</div>
									</div>
								</div>
							</Grid>
						</Grid>
					</div>
					
				</Container>
	
			</Box>
			
			
			<Box component='div' className={`${classes.bannerRowBox} signup_row_box row_box`}>		
				<Container className={classes.root} maxWidth='lg' disableGutters={false} component='div'>
					<div className='content_box'>
						<Grid container spacing={2} justifyContent='center'>
							<Grid item xs={12} className='signup_headline_text'>
								<span className='w-text'>Ayurveda Doctors</span>{' '}
								<span className='w-text'>can SIGNUP</span> with{' '}
								<span className='brand_highlighted_text_weda_green'>WEDAWARU</span> in 2 steps
								<a href='/#/dispensary/signup'>
									<img
										className={`${classes.docSignUp} patient_img_hand`}
										src={docSignUp}
										alt='wedawaru-image'
										align='top'
									/>
								</a>
							</Grid>
						</Grid>
					</div>					
				</Container>	
			</Box>

			


			{/* signup heading container */}
			

			{/* flash video container */}
			{/* <Box component='div' className='video_row_box row_box'>
				<Container className={classes.root} maxWidth='lg' disableGutters={false} component='div'>
					<div className='content_box'>
						<Grid container spacing={0} justifyContent='center'>
							<Grid item xs={12}>
								<div className=''>
									<video controls autoPlay muted volume='0.5' width='100%' height='90%'>
										<source
											id='mp4'
											src='https://ezdoc-contents.s3.ap-south-1.amazonaws.com/ezDOC.mp4'
											type='video/mp4'
										/>
									</video>
								</div>
							</Grid>
						</Grid>
					</div>
				</Container>
			</Box> */}

			{/* patient_view container */}

			<Box component='div' className='patient_row_box row_box'>
				<Container className={classes.root} maxWidth='lg' disableGutters={false} component='div'>
					<div className='content_box'>
						<Grid container spacing={1} justifyContent='space-evenly'>
							<Grid item xs={12} sm={12} md={5} lg={5} className={classes.patientLeft}>
								{/* images */}
								<div className={`${classes.patientLeft} image_box_block`}>
									{isMobile ? (
										<div className={`${classes.mainHeadingBlock} main_heading_block`}>
											<span className='secondary_highlighted_text'>
												After you Register as an Ayurvedic Doctor,
											</span>
											<br /> You are visible on WEDAWARU
											<br />
										</div>
									) : (
										<span className='caption_text secondary_highlighted_text'>
											Patient's view
										</span>
									)}
									<img
										className={`${classes.patientImgHand} patient_img_hand`}
										src={patientMobile}
										alt='patients-image'
										align='top'
									/>
								</div>
							</Grid>
							{/* text */}
							<Grid item xs={12} sm={12} md={7} lg={7} className={classes.patientRight}>
								<div className='text_box_block'>
									{!isMobile ? (
										<div className={`${classes.mainHeadingBlock} main_heading_block`}>
											<span className='secondary_highlighted_text'>
												After you Register as a Ayurvedic Doctor,
											</span>
											<br /> You are visible on wedawaru
											<br />
											<span className=''>
												Patients can find you & book appointments
											</span>
										</div>
									) : (
										<div className={`${classes.mainHeadingBlock} main_heading_block`}>
											<span className=''>
												Patients can find you &<br /> book appointments
											</span>
										</div>
									)}
									<div className='bullet_point_block'>
										{/* list headline */}
										<span className='bullet_point_heading'>
											Benefits for your Patients
										</span>
										{/* list items */}
										<Box component='div'>
											<BenefitsListCard cardType='patient' />
										</Box>
									</div>
								</div>
							</Grid>
						</Grid>
					</div>
				</Container>
			</Box>

			{/* dispensary_view container */}

			<Box component='div' className={`${classes.bannerRowBox} bookappt_row_box row_box`}>
						<Grid container spacing={2} justifyContent='center' >
							<Grid item xs={12} className='signup_headline_text'>
								<span className='w-text'>Booking an appointment with </span>
								<span className='brand_highlighted_text_weda'>WEDAWARU</span>
							</Grid>
						</Grid>
			</Box>

			{/* googleapps container */}
			<Box component='div' className='googleapps_row_box row_box'>
				<Container className={classes.root} maxWidth='lg' disableGutters={false} component='div'>
					<div className='content_box'>
						<Grid container spacing={2} justifyContent='center'>
							<Grid item xs={12} sm={12} md={6} lg={6}>
								<div className='left_block'>
									<Grid container spacing={2} justifyContent='center'>
										<Grid item xs={12}>
											<div className='heading_box'>For Patients</div>
										</Grid>
										<Grid item xs={12}>
											<div className='logo_box'>
												<Grid
													container
													spacing={1}
													justifyContent='center'
													className={`${classes.logoBoxIcons} logo_box_icons`}>
													<Box
														component='div'
														className={`${classes.iconTop} icon_top default`}>
														<img
															src={defaultLogo}
															className=''
															alt='mobile-gallery-logo'
														/>
													</Box>

													<Box
														component='div'
														className={`${classes.iconBottom} icon_bottom default`}>
														<p className='tags'>
															Price:{' '}
															<span className='secondary_highlighted_text'>
																FREE
															</span>
														</p>
														{/* google play store link */}
														<a
															href='https://play.google.com/store/apps/details?id=com.enqbator.wedawaru&hl=en&gl=US'
															target='_blank'>
															<img
																src={googlePlayLogo1}
																className={classes.googleImageLink}
																alt='mobile-googleplay-logo'
																align='top'
															/>
														</a>
														{/* apple store link */}
														<a
															href='https://apps.apple.com/lk/app/wedawaru/id1645064214'
															target='_blank'>
															<img
																src={appleStoreLogo1}
																className={classes.appleImageLink}
																alt='mobile-applestore-logo'
																align='top'
															/>
														</a>
													</Box>
												</Grid>
											</div>
										</Grid>
									</Grid>
								</div>
							</Grid>
							{/* end left */}
							{/* Right Block */}
							<Grid item xs={12} sm={12} md={6} lg={6}>
								<div className='right_block'>
									<Grid container spacing={2} justifyContent='center'>
										<Grid item xs={12}>
											<div className='heading_box'>For Ayurvedic Doctors & Hospitals</div>
										</Grid>
										<Grid item xs={12}>
											<div className='logo_box'>
												<Grid
													container
													spacing={1}
													justifyContent='center'
													className={`${classes.logoBoxIcons} logo_box_icons`}>
													<Box
														component='div'
														className={`${classes.iconTop} icon_top pro`}>
														<img
															src={proLogo}
															className=''
															alt='mobile-gallery-logo'
														/>
													</Box>

													<Box
														component='div'
														className={`${classes.iconBottom} icon_bottom pro`}>
														<p className='tags'>
															Price:{' '}
															<span className='secondary_highlighted_text'>
																FREE
															</span>
														</p>
														{/* google play store link */}
														<a
															href='https://play.google.com/store/apps/details?id=com.enqbator.wedawaruplus&hl=en&gl=US'
															target='_blank'>
															<img
																src={googlePlayLogo1}
																className={classes.googleImageLink}
																alt='mobile-googleplay-logo'
																align='top'
															/>
														</a>
														{/* apple store link */}
														<a
															href='https://apps.apple.com/lk/app/wedawaru-plus/id6443568473'
															target='_blank'>
															<img
																src={appleStoreLogo1}
																className={classes.appleImageLink}
																alt='mobile-applestore-logo'
																align='top'
															/>
														</a>
													</Box>
												</Grid>
											</div>
										</Grid>
									</Grid>
								</div>
							</Grid>
							{/* end right */}
						</Grid>
					</div>
				</Container>
			</Box>
		</Fragment>
	)
}

function mapStateToProps(state) {
	return {}
}

function mapDispatchToProps(dispatch) {
	return {
		dispensaryActions: bindActionCreators(dispensaryActions, dispatch),
		doctorActions: bindActionCreators(doctorActions, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
