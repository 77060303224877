import React, { Fragment } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { Container, Box } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1
	},
	benefitListCard: {
		padding: '1em 0em'
	},
	benefitListGroup: {
		backgroundColor: 'transparent',
		display: 'flex',
		float: 'left',
		clear: 'both',
		width: '100%',
		padding: '5px 0px',
		margin: '0em 0em',
		border: '0px dotted #ccc'
	},
	benefitListIcon: {
		color: '#39cfba',
		paddingRight: '10px'
	},
	benefitListText: {
		color: '#0e2c39'
	}
	// iconColors : {
	//     color: '#0e2c39'
	// }
}))

const BenefitsListCard = props => {
	const { cardType } = props
	const classes = useStyles()

	function iconStyles() {
		return {
			bulletIcon: {
				color: '#0e2c35'
			},
			errorIcon: {
				color: 'red'
			}
		}
	}

	const icons = makeStyles(iconStyles)()

	function dispensary() {
		return (
			<Fragment>
				<div className={`${classes.benefitListGroup} benefit_list_group`}>
					<span className={`${classes.benefitListIcon} benefit_list_icon `}>
						<CheckCircleIcon />
					</span>
					<span className={`${classes.benefitListText} benefit_list_text heading_H5`}>
						A comprehensive <span className='w-text'>Patient Management System</span> for{' '}
						<span className='w-text'>FREE</span>
					</span>
				</div>
				<div className={`${classes.benefitListGroup} benefit_list_group`}>
					<span className={`${classes.benefitListIcon} benefit_list_icon `}>
						<CheckCircleIcon />
					</span>
					<span className={`${classes.benefitListText} benefit_list_text heading_H5`}>
						Supports 3 types of Appointment bookings: Online, Walk-in, and Call-in
					</span>
				</div>
				<div className={`${classes.benefitListGroup} benefit_list_group`}>
					<span className={`${classes.benefitListIcon} benefit_list_icon `}>
						<CheckCircleIcon />
					</span>
					<span className={`${classes.benefitListText} benefit_list_text heading_H5`}>
						Patients find New Doctors online
					</span>
				</div>
				<div className={`${classes.benefitListGroup} benefit_list_group`}>
					<span className={`${classes.benefitListIcon} benefit_list_icon `}>
						<CheckCircleIcon />
					</span>
					<span className={`${classes.benefitListText} benefit_list_text heading_H5`}>
						Doctors can see upcoming Patients List ahead of time
					</span>
				</div>
				<div className={`${classes.benefitListGroup} benefit_list_group`}>
					<span className={`${classes.benefitListIcon} benefit_list_icon `}>
						<CheckCircleIcon />
					</span>
					<span className={`${classes.benefitListText} benefit_list_text heading_H5`}>
						Digital Check-in/Check-out for all the patients at dispensaries
					</span>
				</div>

				<div className={`${classes.benefitListGroup} benefit_list_group`}>
					<span className={`${classes.benefitListIcon} benefit_list_icon `}>
						<CheckCircleIcon />
					</span>
					<span className={`${classes.benefitListText} benefit_list_text heading_H5`}>
						Maintain Digital Patient Records
					</span>
				</div>
				<div className={`${classes.benefitListGroup} benefit_list_group`}>
					<span className={`${classes.benefitListIcon} benefit_list_icon `}>
						<CheckCircleIcon />
					</span>
					<span className={`${classes.benefitListText} benefit_list_text heading_H5`}>
						Dispensary Staff to manage all day to day tasks via ezDOC app
					</span>
				</div>
				<div className={`${classes.benefitListGroup} benefit_list_group`}>
					<span className={`${classes.benefitListIcon} benefit_list_icon `}>
						<CheckCircleIcon />
					</span>
					<span className={`${classes.benefitListText} benefit_list_text heading_H5`}>
						Admin user has the visibility on all activities, data & finance
					</span>
				</div>
			</Fragment>
		)
	}

	function patient() {
		return (
			<Fragment>
				<div className={`${classes.benefitListGroup} benefit_list_group`}>
					<span className={`${classes.benefitListIcon} benefit_list_icon `}>
						<CheckCircleIcon />
					</span>
					<span className={`${classes.benefitListText} benefit_list_text heading_H5`}>
						Find the nearby Ayurvedic doctors & their availabilities online
					</span>
				</div>
				<div className={`${classes.benefitListGroup} benefit_list_group`}>
					<span className={`${classes.benefitListIcon} benefit_list_icon `}>
						<CheckCircleIcon />
					</span>
					<span className={`${classes.benefitListText} benefit_list_text heading_H5`}>
						No more calling or visiting the Ayurvedic dispensary in advance for appointment booking
					</span>
				</div>
				<div className={`${classes.benefitListGroup} benefit_list_group`}>
					<span className={`${classes.benefitListIcon} benefit_list_icon `}>
						<CheckCircleIcon />
					</span>
					<span className={`${classes.benefitListText} benefit_list_text heading_H5`}>
						Book Ayurvedic doctor appointments easily & quickly through an app
					</span>
				</div>
				<div className={`${classes.benefitListGroup} benefit_list_group`}>
					<span className={`${classes.benefitListIcon} benefit_list_icon `}>
						<CheckCircleIcon />
					</span>
					<span className={`${classes.benefitListText} benefit_list_text heading_H5`}>
						Ability to choose a preferred and convenient appointment time well in advance
					</span>
				</div>
				<div className={`${classes.benefitListGroup} benefit_list_group`}>
					<span className={`${classes.benefitListIcon} benefit_list_icon `}>
						<CheckCircleIcon />
					</span>
					<span className={`${classes.benefitListText} benefit_list_text heading_H5`}>
						<span className='w-text'>Avoid long queues</span> and{' '}
						<span className='w-text'>shorten the total time spent</span> at dispensaries
					</span>
				</div>
				<div className={`${classes.benefitListGroup} benefit_list_group`}>
					<span className={`${classes.benefitListIcon} benefit_list_icon `}>
						<CheckCircleIcon />
					</span>
					<span className={`${classes.benefitListText} benefit_list_text heading_H5`}>
						Maintain <span className='w-text'>social-distancing</span>
					</span>
				</div>
			</Fragment>
		)
	}

	return <div className={classes.benefitListCard}>{cardType === 'patient' ? patient() : dispensary()}</div>
}
export default BenefitsListCard
