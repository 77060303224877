import React, { useState, useEffect } from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import * as authActions from '../containers/forms/auths/redux/actions'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { AppBar, Toolbar, IconButton, Box, Container } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import brandLogo from '../images/wedawaru_logo_lg.png'
import { bindActionCreators } from 'redux'
import { NAVBAR_ROUTES } from 'constants/NavConstants'

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		// TODO zoom
		[theme.breakpoints.between('md', 'lg')]: {
			zoom: '0.9'
		}
	},
	navbarStyle: {
		background: '#006837',
		color: '#0e2c39'
	},
	brandLogoIcon: {
		width: 'auto',
		height: 'auto',
		marginLeft: '-1em',
		[theme.breakpoints.down('sm')]: {
			//flexGrow: 1,
		}
	},
	hamburgerMenuIcon: {
		color: '#39cfba'
	},

	title: {
		marginRight: theme.spacing(0),
		[theme.breakpoints.down('sm')]: {
			flexGrow: 1
		}
	},

	headerOptions: {
		display: 'flex',
		flex: 1,
		justifyContent: 'space-evenly'
	},
	headerLink: {
		'&:after': {
			border: '0px solid #222'
		},
		'&:hover': {
			textDecoration: 'none',
			color: '#39cfba'
		}
	}
}))

const Navbar = props => {
	const { history, location } = props
	const classes = useStyles()
	const theme = useTheme()
	const [show, setShow] = useState(true)

	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const auth = useSelector(state => state.authReducer.loggedIn)

	// import from exteranal class
	const menuLinkItems = [
		{
			title: 'Home',
			path: '/'
		},
		{
			title: 'Become an WEDAWARU-DOCTOR',
			path: '/dispensary/signup'
		},
		{
			title: 'Contact Us',
			path: '/contact'
		},
		{
			title: 'Ayurvedic Hospital Owner Login',
			path: '/dispensary/login',
			activeLink: false
		},
		{
			title: 'Hospital Owner Portal',
			path: '/dispensary/portal-web'
		},
		{
			title: 'Kiosk',
			path: '/kiosk'
		},
		{
			title: 'Now Serving',
			path: '/now-serving'
		},
		{
			title: 'Logout',
			path: '/dispensary/login' // Do not use [/] path :: redirect to login page.
		}
	]

	useEffect(() => {
		const path = location.pathname
		setShow(
			path === '/' ||
			path === '/contact' ||
			path === '/dispensary/login' ||
			path === '/dispensary/portal-web'
		)
	})

	useEffect(() => {
		if (!auth) {
			// TODO set after
			// history.push(NAVBAR_ROUTES.HOME)
			// setAnchorEl(null)
		}
	})

	const handleMenu = event => {
		setAnchorEl(event.currentTarget)
	}

	const handleMenuClick = (pathUrl, title) => {
		if (title === 'Logout') {
			props.authActions.setLoggedIn(false)
			props.authActions.signOut()
		} else {
			history.push(pathUrl)
			setAnchorEl(null)
		}
	}

	const updateMenuItem = () => {
		if (auth) {
			menuLinkItems.splice(3, 1)
		} else {
			menuLinkItems.splice(4)
			menuLinkItems.splice(5)
			menuLinkItems.splice(6)
		}
		return menuLinkItems
	}

	const watchExactPath = () => {
		const { history } = props
		return history.location.pathname
	}

	return show ? (
		<div className={classes.root}>
			<AppBar position='fixed' className={classes.navbarStyle}>
				{open && isMobile ? (
					<Container className='mobile_menu' disableGutters={false}>
						{updateMenuItem().map((menuItem, index) => {
							const { title, path } = menuItem
							return (
								<NavLink
									to={path}
									key={index}
									onClick={() => handleMenuClick(path, title)}
									className={`navbar_link_mobile`}>
									{title}
								</NavLink>
							)
						})}
					</Container>
				) : null}

				<Container maxWidth='lg' disableGutters={false}>
					<Toolbar>
						<Box component='span' className={classes.title}>
							<img src={brandLogo} className={classes.brandLogoIcon} alt='Logo' />
						</Box>

						{isMobile ? (
							<>
								<IconButton
									edge='start'
									className={classes.hamburgerMenuIcon}
									color='inherit'
									fontSize='large'
									aria-label='menu'
									onClick={handleMenu}>
									<MenuIcon />
								</IconButton>

								{/* mobile navItem end */}
							</>
						) : (
							<div className={classes.headerOptions}>
								{updateMenuItem().map((menuItem, index) => {
									const { title, path } = menuItem
									const isActive = watchExactPath().trim() === path.trim()

									return (
										<NavLink
											to={path}
											key={index}
											onClick={() => handleMenuClick(path, title)}
											className={`navbar_link`}
											activeClassName={`${isActive ? 'nav_link_actived' : ''}`}>
											{title}
										</NavLink>
									)
								})}
							</div>
						)}
					</Toolbar>
				</Container>
			</AppBar>
		</div>
	) : null
}

function mapStateToProps(state) {
	return {
		dispensaryState: state.dispensaryReducer
	}
}

function mapDispatchToProps(dispatch) {
	return {
		authActions: bindActionCreators(authActions, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar))
