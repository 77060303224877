import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import * as subscriptions from '../../graphql/subscriptions'
import * as kioskActions from '../../containers/forms/Kiosk/redux/actions'
import { bindActionCreators } from 'redux'
// import { updateBookedSlot } from 'containers/forms/Kiosk/redux/actions';

export class GlobalSubscriptionListner {
    constructor() {
    }
    static slotSubcribers = []


    static subscribeSlotUpdates = (id, onSlotMassage) => {
        const dataArray = GlobalSubscriptionListner.slotSubcribers
        GlobalSubscriptionListner.slotSubcribers = [...dataArray, { id, onSlotMassage }]
    }

    static unSubscriveSlotUpdates = (id) => {
        GlobalSubscriptionListner.slotSubcribers = GlobalSubscriptionListner.slotSubcribers.filter(pair => pair.id != id)
    }

};

const AppsyncSubscription = (props) => {
    const { updateBookedSlot, dispensaryInfo } = props
    const [currentState, setCurrentState] = useState('active')


    const onSubscriptionReslut = ({ provider, value }) => {
        const { data: { onAppointmentUpdate: doctorSlotUpdate } } = value
        if (doctorSlotUpdate) {
            props.appointmentsActions.updateBookedSlot(doctorSlotUpdate)
        }
    }

    useEffect(() => {

        var subscription = null
        if (dispensaryInfo) {
            subscription = API.graphql(
                graphqlOperation(subscriptions.onAppointmentUpdate, { "dispensary_id": dispensaryInfo.email })
            ).subscribe({
                next: onSubscriptionReslut,
                error: error => console.error(error.error.errors)
            });
        }
        return () => {
            subscription && subscription.unsubscribe()
        }
    }, [currentState])

    // const refreshTodayApoimentsForDispencery = (inBackground = false) =>{
    //     const payload = {
    //         requestBackground: inBackground,
    //         date: new Date()
    //     };

    //     requestAppointments(payload);
    // }

    return (
        null
    )
}

const mapStateToProps = (state) => {
    return {
        dispensaryInfo: state.authReducer.currentUserInfo.attributes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        appointmentsActions: bindActionCreators(kioskActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppsyncSubscription)
