import { put, call, select } from 'redux-saga/effects';
import { Auth } from 'aws-amplify'
import {
    requestAppointments,
    cancelAppointment,
    getDoctorsByDispensary,
    bookAppointment,
    addNewPatient
} from '../api/index';
import * as appActions from '../../../../store/AppStore/appActions';
import * as actions from '../redux/actions';
import { dispensary_id, modify_slot } from '../../auths/redux/selector';
import { getCurrentDate, serverDateToString, dayInText, serverDateToTime } from '../../../../utils';
import { Navigation } from 'react-router';
import { NAVBAR_ROUTES } from 'constants/NavConstants';
import { push } from 'react-router-redux';
import { useHistory } from "react-router-dom";
import moment from 'moment-timezone'



export function* getAppointmentsSaga(payload) {
    yield put(appActions.showLoading())
    let response = {};
    // If true API call in background, and no any UI interaction
    // const requestBackground = payload.requestBackground || false;
    const dispensaryId = payload.payload.attributes.email//yield select(dispensary_id);
    const date = moment.tz(new Date(), 'Asia/Colombo')

    const params = {
        dispensary_id: dispensaryId,
        date: serverDateToString(date),
        day: dayInText(date)
    };
    const user = yield Auth.currentAuthenticatedUser()
    const token = user.signInUserSession.idToken.jwtToken
    const options = {
        headers: {
            Authorization: token
        }
    }

    try {
        // if (!requestBackground) {
        //     // yield put(appActions.showFullLoader());
        // }
        response = yield call(requestAppointments, params, options);
        // yield put(appActions.hideFullLoader());

        yield put(actions.responseAppointments(response.data));

        const doctorInfo = dispensaryId;
        const paramsConsultation = {
            doctorInfo: doctorInfo,
            appointments: response.data
        };
        yield put(appActions.hideLoading())

    } catch (error) {
        yield put(appActions.hideLoading())

    }
}


export function* bookAppointmentSaga(action) {
    yield put(appActions.showSendSmsLoading())
    let responseAddNewPatientResponse = {};
    let response = {};
    const payload = action.payload;
    const dispensaryId = yield select(dispensary_id);
    const modifySlot = yield select(modify_slot);
    const date = moment.tz(new Date(), 'Asia/Colombo')



    const user = yield Auth.currentAuthenticatedUser()
    const token = user.signInUserSession.idToken.jwtToken
    const options = {
        headers: {
            Authorization: token
        }
    }


    const body = {
        day: dayInText(date),
        date: serverDateToString(date),
        dispensary_id: payload.dispensaryId,
        doctor_id: payload.doctorId,
        family_member: payload.familyMember,
        symptom: payload.symptom,
        booking_method: payload.bookingType,
        auto_checked_in: payload.autoCheckedIn,
        previous_ap_dt: modifySlot ? modifySlot.slot_time : null,
        display_name: payload.displayName,
        kiosk: payload.kiosk,
        mobile_number: payload.mobileNumber,
        first_name: payload.firstName,
    };

    try {
        const params = {
            patient_id: payload.mobileNumber
        };

        response = yield call(bookAppointment, params, body, options);

        if (response.status === 200) {
            // yield put(actions.updateBookedSlot({ slot: response.data.message }));
            yield put(actions.responseBookAppointment(response.data.message));

            yield put(push(NAVBAR_ROUTES.KIOSK_APPOINTMENT_LAYOUT));
        }
        yield put(appActions.hideSendSmsLoading())
    } catch (error) {
        yield put(appActions.hideSendSmsLoading())
    }
}

export function* requestDoctorsByDispensarySaga(payload) {
    let response = {};

    const dispensaryId = payload.payload.attributes.email;
    try {
        const params = {
            dispensary_id: dispensaryId,
            date: getCurrentDate()
        };
        const user = yield Auth.currentAuthenticatedUser()
        const token = user.signInUserSession.idToken.jwtToken
        const options = {
            headers: {
                Authorization: token
            }
        }
        response = yield call(getDoctorsByDispensary, params, options);

        const doctors = Array.isArray(response.data) ? response.data : [];

        if (response.status === 200) {//APIStatusCode.SUCCESS
            // yield put(actions.responseDoctorsByDispensary(response));
            yield put(actions.updateDoctorList(doctors));
        } else {
            yield put(actions.responseDoctorsByDispensary({}));
        }
    } catch (error) {
        // yield put(appActions.hideFullLoader());
        // yield call(handleErrors, error);
    }
}

export function* navigateToKioskSaga() {

    yield put(push(NAVBAR_ROUTES.KIOSK));

}

export function* navigateToKioskMobileNumberSaga(data) {
    yield put(actions.setFirstName(data));
    yield put(push(NAVBAR_ROUTES.KIOSK_MOBILE_NUMBER, data));
}




export function navigate(name, params) {

}

