import React, { StrictMode } from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import SpinnerModal from 'components/SpinnerModal'
import SendSMSModal from 'components/SendSMSModal'
import App from 'containers/App'
import { PersistGate } from 'redux-persist/integration/react'
import ErrorModal from 'components/errors/ErrorModal'
import FullScreenModal from './errors/FullScreenModal'

const theme = createMuiTheme({
	palette: {

		primary: {
			main: '#FBC02E',
			light: '#F9DC7C',
			dark: '#EFD28B',
			contrastText: '#000000'
		},
		secondary: {
			main: '#0E2C39', // #FFFFFF
			light: '',
			dark: '',
			contrastText: '#F4F4F4'
		},
		error: {
			main: '#ba0000',
			light: ''
		},
		background: '#f4f5fd'
	}
})

const Root = props => {
	const { store, history, persistor } = props
	return (
		<ThemeProvider theme={theme}>
			<Provider store={store}>
				<ConnectedRouter history={history}>
					<PersistGate persistor={persistor}>
						<App />
						<ErrorModal history={history} />
						<FullScreenModal />
						<SpinnerModal />
						<SendSMSModal />
					</PersistGate>
				</ConnectedRouter>
			</Provider>
		</ThemeProvider>
	)
}

export default Root
