import React, { useState, useEffect, Fragment } from 'react'
import { Box, Container } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import proLogo from '../../../images/wedawaru_logo_white.png'
// import googleAppsLogo from '../../../images/mobile-app-store.png';
import appleStoreLogo1 from '../../../images/mobile-app-store1.png';
import googlePlayLogo1 from '../../../images/mobile-google-play1.png';

const useStyles = makeStyles(theme => ({
	rootTop: {
		flex: 1,
		boxSizing: 'border-box',
		background: '#DFC19680'
	},
	rootBottom: {
		flex: 1,
		boxSizing: 'border-box',
		background: '#FFFFFF'
	},
	primaryHeading: {
		fontSize: 'clamp(25px, 2.5vw, 40px)',
		fontFamily: 'Roboto Bold ,sans-serif',
		color: '#0E2C39',
		padding: `${theme.spacing(0)}px ${theme.spacing(5)}px`,
		[theme.breakpoints.down('xs')]: {
			padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`
		}
	},
	secondaryHeading: {
		width: 'clamp( calc(100% - 2.5rem), calc(100% - 7rem) , calc(100% - 2rem))',
		fontSize: 'clamp(16px, 2.3vw, 30px)',
		fontFamily: 'Roboto Light ,sans-serif',
		color: '#0E2C39',
		padding: `${theme.spacing(1)}px ${theme.spacing(5)}px`,
		[theme.breakpoints.down('xs')]: {
			padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`
		}
	},
	subHeadingBox: {
		fontSize: 'clamp(16px, 1.1vw, 24px)',
		fontFamily: 'Roboto Light ,sans-serif',
		color: '#159782',
		fontStyle: 'italic',
		padding: `${theme.spacing(3)}px ${theme.spacing(5)}px`,
		[theme.breakpoints.down('xs')]: {
			padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`
		}
	},
	logoBox: {
		// border: '1px dotted green',
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: `${theme.spacing(5)}px ${theme.spacing(5)}px`,
		[theme.breakpoints.down('xs')]: {
			padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`
		}
	},
	appImgPro: {
		width: '350px',
		height: '350px',
		[theme.breakpoints.down('xs')]: {
			width: '125px',
			height: '125px'
		}
	},
	appImgGoogle: {
		width: '300px',
		height: 'auto',
		[theme.breakpoints.down('xs')]: {
			width: '150px'
		}
	},
	googleImageLink: {
		marginBottom: theme.spacing(1),
		width: '100%',
		height: '100%',
		'&:hover': {
			filter: 'drop-shadow(0 0 0.60rem #BBB)'
		}
	},
	appleImageLink: {
		width: '100%',
		height: '100%',
		marginTop: theme.spacing(0),
		'&:hover': {
			filter: 'drop-shadow(0 0 0.60rem #BBB)'
		}
	},
	wBold: {
		fontFamily: 'Roboto Medium ,sans-serif',
		padding: `${theme.spacing(0)}px ${theme.spacing(0.2)}px`
	},
	wBoldItalic: {
		fontFamily: 'Roboto Medium ,sans-serif',
		padding: `${theme.spacing(0)}px ${theme.spacing(0.2)}px`
	},
	logoPro: {
		width: '100%',
		height: '100%'
	},
	logoGoogleapp: {
		width: '100%',
		height: '100%'
	},
	imgCaptionTextBox: {
		textAlign: 'center',
		fontFamily: 'Roboto Medium ,sans-serif',
		fontSize: 'clamp(16px, 1.2vw, 30px)',
		paddingBottom: theme.spacing(5),

		[theme.breakpoints.down('xs')]: {
			paddingBottom: theme.spacing(0.2)
		}
	}
}))

const AccessGrantInfoBox = props => {
	const classes = useStyles()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
	const { show } = props

	return (
		<Fragment>
			<Box
				component='div'
				display='flex'
				flexDirection='column'
				justifyContent='center'
				alignItems='flex-start'
				className={classes.rootTop}>
				<div className={classes.primaryHeading}>Congratulations!</div>
				<div className={classes.secondaryHeading}>
					You’ve been granted <span className={classes.wBold}> full-access-rights </span>
					to the <span className={classes.wBold}> WEDAWARU PLUS App </span>
				</div>
			</Box>
			<Box
				component='div'
				display='flex'
				flexDirection='column'
				justifyContent='center'
				alignItems='flex-start'
				className={classes.rootBottom}>
				<div className={classes.subHeadingBox}>
					Download the app — Use it for your day-to-day{' '}
					<span className={classes.wBoldItalic}>Hospital Operations & Management...</span>
				</div>
				<div className={classes.logoBox}>
					<Box
						component='div'
						display='flex'
						flexDirection='column'
						justifyContent='flext-start'
						alignItems='flext-start'
						alignSelf='flext-start'
						className={classes.appImgPro}>
						<img src={proLogo} className={classes.logoPro} alt='ezdoc-pro' />
					</Box>
					<Box
						component='div'
						display='flex'
						flexDirection='column'
						justifyContent='flext-start'
						alignItems='flext-start'
						alignSelf='flext-start'
						className={classes.appImgGoogle}>
						<Fragment>
							<p className={classes.imgCaptionTextBox}>
								Price: <span className='secondary_highlighted_text'>FREE</span>
							</p>
							{/* <img src={googleAppsLogo} className={classes.logoGoogleapp} alt="google-app-logo" align="top" /> */}
							<a href='https://play.google.com/store/apps/details?id=com.enqbator.wedawaruplus&hl=en&gl=US' target='_blank'>
								<img
									src={googlePlayLogo1}
									className={classes.googleImageLink}
									alt='mobile-googleplay-logo'
									align='top'
								/>{' '}
							</a>
							{/* apple store link */}
							<a href='https://apps.apple.com/lk/app/wedawaru-plus/id6443568473' target='_blank'>
								<img
									src={appleStoreLogo1}
									className={classes.appleImageLink}
									alt='mobile-applestore-logo'
									align='top'
								/>{' '}
							</a>
						</Fragment>
					</Box>
				</div>
			</Box>
		</Fragment>
	)
}

export default AccessGrantInfoBox
