import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { Box, Container } from '@material-ui/core'

import * as authActions from '../auths/redux/actions'
import * as dispensaryActions from '../Dispensary/redux/actions'
import * as doctorActions from './redux/actions'
import { NAVBAR_ROUTES } from 'constants/NavConstants'
import { FORM_DOCTOR } from 'constants/FormConstants'
import FormWizardLayout from 'containers/forms/components/FormWizardLayout'
import FormOTPWizardLayout from 'containers/forms/components/shared/FormOTPWizardLayout'
import SuccessWizardLayout from 'containers/forms/components/shared/SuccessWizardLayout'
import DoctorAddForm from 'containers/forms/doctor/DoctorAddForm'
import DoctorNicUploadWizard from 'containers/forms/doctor/DoctorNicUploadWizard'
import DoctorOptionWizard from 'containers/forms/doctor/DoctorOptionWizard'
import { AWSStatusCode } from 'config/AWSStatusCode'
import base64 from 'react-native-base64'

const styles = () => ({
	root: {
		flexGrow: 1
	}
})

class DoctorLayout extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			owner: false,
			update: false
		}
	}

	componentDidMount() {
		const { history, doctorActions, dispensaryActions } = this.props
		let temp_dispensary = new URLSearchParams(history.location.search).get('dispensary_id')

		if (temp_dispensary !== null) {
			this.props.authActions.signOut()
			doctorActions.updateFormStep(2)
			const dispensary_id = base64.decode(temp_dispensary)
			dispensaryActions.setTempDispensary(dispensary_id)
		}
		if (this.props.location.doctor) {
			this.setState(
				{
					owner: true
				},
				() => {
					doctorActions.updateTermAndCondition(false)
					this.setState({ update: true }, () => {
						const payload = {
							doctorTitle: this.props.location.doctor.initials,
							initials: this.props.location.doctor.first_name,
							lastName: this.props.location.doctor.last_name,
							displayName: this.props.location.doctor.display_name,
							displayNameInSinhala: this.props.location.doctor.display_name_sinhala,
							SLMCRegNo: this.props.location.doctor.slmc_registratoin_number,
							specility: this.props.location.doctor.speciality,
							treatment_method: this.props.location.doctor.treatment_method,
							doctorFee: this.props.location.doctor.consultation_fee,
							education: this.props.location.doctor.educations,
							mobile: this.props.location.doctor.contact_number,
							email: this.props.location.doctor.email,
							emailConfirm: '',
							password: '',
							image_url: this.props.location.doctor.image_url,
							verified: this.props.location.doctor.verified,
							city: 'Colombo'
						}
						doctorActions.setDoctorInfoToForm(payload)
						doctorActions.updateFormStep(2)
					})
				}
			)
		}
	}

	componentDidUpdate(prevProps) {
		const {
			imageUploadResponse,
			createAWSDoctorResponse,
			doctorActions,
			invitationDoctorResponse,
			tempDispensary,
			assignDoctorToDispensaryResponse,
			tempDoctor
		} = this.props

		const prevResponse = prevProps.doctorState.createAWSDoctorResponse
		if (createAWSDoctorResponse.status === AWSStatusCode.SUCCESS) {
			if (createAWSDoctorResponse.uuid !== prevResponse.uuid) {
				doctorActions.updateFormStep(3)
			}
		}

		if (prevProps.doctorSignInResponse.uuid !== this.props.doctorSignInResponse.uuid) {
			if (this.props.doctorSignInResponse.status === AWSStatusCode.SUCCESS) {
				const { doctorActions } = this.props
				const payload = this.props.authState.tempDoctor
				doctorActions.submitDoctorToApi(payload)
				doctorActions.updateFormStep(4)
				
			}
		}

		if (prevProps.assignDoctorToDispensaryResponse.uuid !== assignDoctorToDispensaryResponse.uuid) {
			this.navigateToNicUploadWizard()
		}

		if (prevProps.invitationDoctorResponse.uuid !== invitationDoctorResponse.uuid) {
			const payload = {
				dispensary: tempDispensary,
				doctor: tempDoctor.mobile_number
			}
			doctorActions.assignDoctorToDispensaryRequest(payload)
		}

		if (prevProps.imageUploadResponse !== imageUploadResponse) {
			if (imageUploadResponse.status === AWSStatusCode.SUCCESS) {
				this.nextStep()
			}
		}
	}

	handleTermsAndConditions = e => {
		const { doctorActions } = this.props
		const checked = e.target.checked
		doctorActions.updateTermAndCondition(checked)
	}

	handleInputChange = e => {
		const { doctorActions } = this.props
		const { name, value } = e.target
		doctorActions.onChangeField(name, value)
	}

	nextStep = () => {
		const { doctorState, doctorActions } = this.props
		const { step } = doctorState
		const nextPageCount = step + 1
		doctorActions.updateFormStep(nextPageCount)
	}

	nextStepWithOwner = () => {
		const { doctorState, doctorActions } = this.props
		const { step } = doctorState
		this.setState(
			{
				owner: true
			},
			() => {
				const nextPageCount = step + 1
				doctorActions.updateFormStep(nextPageCount)
			}
		)
	}

	navigateToNicUploadWizard = () => {
		const { doctorActions } = this.props
		doctorActions.updateFormStep(4)
	}

	prevStep = () => {
		const { doctorState, doctorActions } = this.props
		const { step } = doctorState
		const prevPageCount = step - 1
		doctorActions.updateFormStep(prevPageCount !== 0 ? prevPageCount : 1)
	}

	exitForm = () => {
		const { history, doctorActions } = this.props
		doctorActions.updateFormStep(1)

		if (this.state.update) {
			history.push(NAVBAR_ROUTES.OWNER_PORTAL_WEB)
		} else {
			history.push(NAVBAR_ROUTES.HOME)
		}
	}

	uploadImages = data => {
		const { doctorActions } = this.props
		doctorActions.uploadImagesToS3(data)
	}

	handleFormSubmit = () => {
		const { doctorState } = this.props
		const { formInputs } = doctorState
	}

	render() {
		const { doctorState } = this.props
		const { step, title, conditionTerms, formInputs } = doctorState

		const { owner, update } = this.state

		const stepper = [
			{
				id: 1,
				label: '1',
				activeStep: true,
				completedStep: true
			},
			{
				id: 2,
				label: '2',
				activeStep: true,
				completedStep: false
			}
		]

		switch (step) {
			case 1:
				return (
					<FormWizardLayout
						showHeading={true}
						mainHeading={title}
						stepper={stepper}
						nextStep={this.nextStep}
						prevStep={this.prevStep}
						exitForm={this.exitForm}>
						<Container maxWidth='lg'>
							<DoctorOptionWizard nextStep={this.nextStepWithOwner} />
						</Container>
					</FormWizardLayout>
				)

			case 2:
				return (
					<FormWizardLayout
						showHeading={true}
						mainHeading={`${title} (1/2)`}
						stepper={stepper}
						nextStep={this.nextStep}
						prevStep={this.prevStep}
						exitForm={this.exitForm}>
						<Container maxWidth='lg'>
							<DoctorAddForm
								owner={owner}
								update={update}
								data={formInputs}
								nextStep={this.nextStep}
								navigateToNicUploadWizard={this.navigateToNicUploadWizard}
								handleInputChange={this.handleInputChange}
								handleTermsAndConditions={this.handleTermsAndConditions}
								conditionTerms={conditionTerms}
							/>
						</Container>
					</FormWizardLayout>
				)
			case 3:
				return <FormOTPWizardLayout formType={FORM_DOCTOR} />
			case 4:
				return (
					<FormWizardLayout
						showHeading={true}
						mainHeading={`${title} (2/2)`}
						stepper={stepper}
						nextStep={this.nextStep}
						prevStep={this.prevStep}
						exitForm={this.exitForm}>
						<Container maxWidth='lg'>
							<DoctorNicUploadWizard
								nextStep={this.nextStep}
								handleFormSubmit={this.handleFormSubmit}
								uploadImagetoS3={this.uploadImages}
							/>
						</Container>
					</FormWizardLayout>
				)
			case 5:
				return (
					<SuccessWizardLayout
						formType={FORM_DOCTOR}
						isLogo={true}
						username={formInputs.displayName}
						exitForm={this.exitForm}
					/>
				)

			default:
				return <Box>Error</Box>
		}
	}
}

function mapStateToProps(state) {
	return {
		authState: state.authReducer,
		doctorState: state.doctorReducer,
		imageUploadResponse: state.doctorReducer.imageUploadResponse,
		createAWSDoctorResponse: state.doctorReducer.createAWSDoctorResponse,
		confirmUserResponse: state.doctorReducer.confirmUserResponse,
		doctorSignInResponse: state.doctorReducer.doctorSignInResponse,
		assignDoctorToDispensaryResponse: state.doctorReducer.assignDoctorToDispensaryResponse,
		tempDoctor: state.authReducer.tempDoctor,
		tempDispensary: state.dispensaryReducer.tempDispensary,
		invitationDoctorResponse: state.doctorReducer.invitationDoctorResponse
	}
}

function mapDispatchToProps(dispatch) {
	return {
		authActions: bindActionCreators(authActions, dispatch),
		doctorActions: bindActionCreators(doctorActions, dispatch),
		dispensaryActions: bindActionCreators(dispensaryActions, dispatch)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles, { withTheme: true })(DoctorLayout))
