import React, {useEffect} from 'react';
import { connect } from 'react-redux'
import {
    isAndroid,
    isIOS
  } from "react-device-detect";

const MobileDownload = (props) => {
    useEffect(() => {
        if (isAndroid) {
          window.location.href =
            "https://play.google.com/store/apps/details?id=com.enqbator.wedawaru&hl=en&gl=US";
        }else if(isIOS) {
          window.location.href =
            "https://apps.apple.com/lk/app/wedawaru/id1645064214";
        } else{
          window.location.href =
            "https://wedawaru.lk/#/";
        }
      }, []);

    return (
        null
    )
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileDownload)
