import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import { Box, Container } from '@material-ui/core'

import Helpers from 'utils/Helpers'

import * as actions from './redux/actions'
import * as authActions from '../auths/redux/actions'
import * as portalActions from '../../portalweb/redux/actions'

import { NAVBAR_ROUTES } from 'constants/NavConstants'
import { FORM_DISPENSARY } from 'constants/FormConstants'
import FormWizardLayout from '../components/FormWizardLayout'
import FormOTPWizardLayout from '../components/shared/FormOTPWizardLayout'
import SuccessWizardLayout from '../components/shared/SuccessWizardLayout'
import DispensaryOwnerForm from './DispensaryOwnerForm'
import DispensaryDetailsForm from './DispensaryDetailsForm'
import { bindActionCreators } from 'redux'
import { AWSStatusCode } from 'config/AWSStatusCode'

import { v4 as uuidv4 } from 'uuid'

export const goToStep = {
	DISPENSARY_OWNER_FORM: 1,
	OTP_FORM: 2,
	DISPENSARY_DETAILS_FORM: 3,
	SUCCESS_SCREEN: 4
}

const styles = () => ({
	root: {
		flexGrow: 1
	}
})

class DispensaryLayout extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			update: false
		}
	}

	componentDidMount() {
		this.props.dispensaryActions.updateFormStep(1)
		if (this.props.location.dispensary) {
			this.setState({ update: true }, console.log('dispensary update', this.state))
			this.setDispensaryDetailsToForm(this.props.location.dispensary)
			this.dispensaryDetailsView()
		} else {
			if (Helpers.isEmpty(this.props.currentUserInfo)) {
				this.props.dispensaryActions.updateFormStep(goToStep.DISPENSARY_OWNER_FORM)
			} else {
				this.props.portalActions.getDispensaryDetailsRequest()
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const prevResponse = prevProps.dispensaryState.createDispensaryOwnerResponse
		const currentResponse = this.props.dispensaryState.createDispensaryOwnerResponse

		if (currentResponse.status === AWSStatusCode.SUCCESS) {
			if (currentResponse.uuid !== prevResponse.uuid) {
				this.props.dispensaryActions.updateFormStep(goToStep.OTP_FORM)
			}
		}

		const prevConfirmUserResponse = prevProps.dispensaryState.confirmDispensaryOwnerResponse
		const currentConfirmUserResponse = this.props.dispensaryState.confirmDispensaryOwnerResponse

		if (currentConfirmUserResponse.status === AWSStatusCode.SUCCESS) {
			if (currentConfirmUserResponse.uuid !== prevConfirmUserResponse.uuid) {
				// this.props.authActions.dispensaryOwnerSignIn()
				this.props.dispensaryActions.updateFormStep(goToStep.DISPENSARY_DETAILS_FORM)
			}
		}

		const prevDispensaryResponse = prevProps.portalState.dispensaryDetails
		const currentDispensaryResponse = this.props.portalState.dispensaryDetails

		if (prevDispensaryResponse !== currentDispensaryResponse) {
			if (currentDispensaryResponse.action === goToStep.DISPENSARY_DETAILS_FORM) {
				this.props.dispensaryActions.updateFormStep(goToStep.DISPENSARY_DETAILS_FORM)
				this.props.dispensaryActions.flowlessRegistration(true)
			} else {
				if (Helpers.isEmpty(currentDispensaryResponse.data)) {
					this.props.dispensaryActions.updateFormStep(goToStep.DISPENSARY_DETAILS_FORM)
				} else {
					const { history } = this.props
					history.push(NAVBAR_ROUTES.DOCTOR_SIGNUP_FORM)
				}
			}
		}

		const prevDispensaryCreateResponse = prevProps.dispensaryState.createDispensaryResponse
		const currentDispensaryCreateResponse = this.props.dispensaryState.createDispensaryResponse

		if (prevDispensaryCreateResponse !== currentDispensaryCreateResponse) {
			if (currentDispensaryCreateResponse.status === 201) {
				this.props.dispensaryActions.updateFormStep(goToStep.SUCCESS_SCREEN)
			}
		}
	}

	skipStep = () => {}

	handleTermsAndConditions = e => {
		const checked = e.target.checked
		this.props.dispensaryActions.updateTermAndCondition(checked)
	}

	handleInputChange = e => {
		const { name, value } = e.target
		this.props.dispensaryActions.onChangeField(name, value)
	}

	nextStep = () => {
		const { dispensaryState } = this.props
		const { step } = dispensaryState
		const nextPageCount = step + 1
		this.props.dispensaryActions.updateFormStep(nextPageCount)
	}

	dispensaryDetailsView = () => {
		this.props.dispensaryActions.updateFormStep(3)
	}

	setDispensaryDetailsToForm = dispensary => {
		const payload = {
			address: dispensary.address,
			businessRegNo: dispensary.br_number,
			dispensaryName: dispensary.name,
			phoneNo: dispensary.contact_number,
			location: {
				lat: dispensary.geo_location.lat,
				lng: dispensary.geo_location.lng
			},
			district: dispensary.district,
			serviceCategory: dispensary.service_category,
			cardReader: dispensary.card_reader,
			city: dispensary.city,
			uuid: uuidv4()
		}
		this.props.dispensaryActions.setDispensaryDetailsToForm(payload)
	}

	prevStep = () => {
		const { dispensaryState } = this.props
		const { step } = dispensaryState
		const prevPageCount = step - 1
		this.props.dispensaryActions.updateFormStep(prevPageCount !== 0 ? prevPageCount : 1)
	}

	exitForm = () => {
		const { history, dispensaryState } = this.props
		const { step } = dispensaryState
		if (this.state.update) {
			history.push(NAVBAR_ROUTES.OWNER_PORTAL_WEB)
		} else {
			if (step === goToStep.DISPENSARY_DETAILS_FORM) {
				this.props.authActions.signOut()
				history.push(NAVBAR_ROUTES.HOME)
			} else {
				history.goBack()
			}
		}
	}

	render() {
		const { dispensaryState, history } = this.props
		const { update } = this.state

		const { step, title, conditionTerms, formInputs } = dispensaryState

		const {
			firstName,
			lastName,
			email,
			emailConfirm,
			phoneNo,
			password,
			dispensaryName,
			businessRegNo,
			location,
			address,
			district,
			serviceCategory,
			cardReader,
			city
		} = formInputs

		const textFieldsOwner = {
			firstName,
			lastName,
			email,
			emailConfirm,
			phoneNo,
			password
		}
		const textFieldsOwnerDetail = {
			dispensaryName,
			businessRegNo,
			address,
			phoneNo,
			district,
			serviceCategory,
			cardReader,
			location,
			city
		}

		const stepper = [
			{
				id: 1,
				label: '1',
				activeStep: true,
				completedStep: false
			},
			{
				id: 2,
				label: '2',
				activeStep: false,
				completedStep: false
			}
		]

		switch (step) {
			case 1:
				return (
					<FormWizardLayout
						showHeading={true}
						mainHeading={title}
						subHeading="Create Ayurvedic Hospital Owner’s Account"
						stepper={stepper}
						prevArrow={false}
						// nextStep={this.nextStep}
						prevStep={this.prevStep}
						exitForm={this.exitForm}>
						<Container maxWidth='lg'>
							<DispensaryOwnerForm
								data={textFieldsOwner}
								nextStep={this.nextStep}
								handleInputChange={this.handleInputChange}
								handleTermsAndConditions={this.handleTermsAndConditions}
								conditionTerms={conditionTerms}
							/>
						</Container>
					</FormWizardLayout>
				)

			case 2:
				return <FormOTPWizardLayout formType={FORM_DISPENSARY} />

			case 3:
				return (
					<FormWizardLayout
						showHeading={true}
						mainHeading={title}
						subHeading='Dispensary Details'
						stepper={stepper}
						prevArrow={true}
						nextStep={this.nextStep}
						prevStep={this.prevStep}
						exitForm={this.exitForm}>
						<Container maxWidth='lg'>
							<DispensaryDetailsForm
								update={update}
								history={history}
								data={textFieldsOwnerDetail}
								nextStep={this.nextStep}
								handleInputChange={this.handleInputChange}
							/>
						</Container>
					</FormWizardLayout>
				)

			case 4:
				return (
					<SuccessWizardLayout
						history={this.props.history}
						formType={FORM_DISPENSARY}
						isLogo={false}
						username={null}
					/>
				)

			default:
				return <Box>Error</Box>
		}
	}
}

function mapStateToProps(state) {
	return {
		currentUserInfo: state.authReducer.currentUserInfo,
		dispensaryState: state.dispensaryReducer,
		portalState: state.portalReducer,
		dispensaryStaffState: state.dispensaryStaffState
	}
}

function mapDispatchToProps(dispatch) {
	return {
		authActions: bindActionCreators(authActions, dispatch),
		portalActions: bindActionCreators(portalActions, dispatch),
		dispensaryActions: bindActionCreators(actions, dispatch)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles, { withTheme: true })(DispensaryLayout))
