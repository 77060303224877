import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import {
	Box,
	Grid,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button
} from '@material-ui/core'
import { ArrowBack, Close } from '@material-ui/icons'

import Widgets from 'components/widgets'
import * as appActions from '../../store/AppStore/appActions'
import * as authActions from '../../containers/forms/auths/redux/actions'
import logo from 'images/wedawaru_logo_lg.png'
import { NAVBAR_ROUTES } from 'constants/NavConstants'

const styles = theme => ({
	root: {
		flex: 1,
		'& .MuiBackdrop-root': {
			background: '#0E2C39'
			//opacity: 0.9 TODO rgba
		},
		'& .MuiBox-root': {
			background: '#EBFFFB 0% 0% no-repeat padding-box'
		},
		'& .MuiDialogActions-root': {
			background: '#FFFFFF 0% 0% no-repeat padding-box'
		},
		'& .MuiDialogContent-root': {
			background: '#FFFFFF 0% 0% no-repeat padding-box'
		}
		// overflow: 'scroll',
		// height:'400px'
	},
	errorTopBox: {
		// border: '1px dotted #003',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center'
		// padding: `${theme.spacing(0.3)}px ${theme.spacing(0.5)}px`,
		// [theme.breakpoints.down('xs')]: {
		// 	padding: `${theme.spacing(0.3)}px ${theme.spacing(0)}px`
		// }
	},
	errorTopLogo: {
		maxWidth: 'auto',
		maxHeight: '50px',
		boxSizing: 'border-box'
	},
	logo: {
		width: 'auto',
		height: '100%',
		transform: 'scale(0.8)',
		[theme.breakpoints.down('xs')]: {
			transform: 'scale(0.7)'
		}
	},
	errorTopTitle: {
		textAlign: 'left',
		paddingLeft: theme.spacing(2),
		width: '100%',
		fontFamily: 'Roboto Condensed,sans-serif',
		color: '#0E2C39',
		fontSize: 'clamp(17px,1.8vw, 40px)',
		[theme.breakpoints.down('xs')]: {
			paddingLeft: theme.spacing(0)
		}
	},
	errorTopClose: {
		color: '#39CFBA',
		marginTop: theme.spacing(0.7),
		marginRight: theme.spacing(0.5),
		[theme.breakpoints.down('xs')]: {
			//paddingLeft: theme.spacing(0),
		}
	},
	errorContentBox: {
		// border: '1px dotted #003',
		backgroundColor: '#FFF',
		width: '100%', // width: 620px;
		padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
		[theme.breakpoints.down('xs')]: {
			padding: `${theme.spacing(2)}px ${theme.spacing(0)}px`
		}
	},
	errorContentText: {
		fontFamily: 'Roboto Medium,sans-serif',
		color: '#0E2C39',
		fontSize: 'clamp(14px,1.3vw, 25px)',
		[theme.breakpoints.up('xl')]: {
			//padding: `${theme.spacing(2)}px ${theme.spacing(0)}px`,
			//fontSize: '24px',
		}
	},
	errorFooterBox: {
		// border: '1px dotted #003',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		margin: `${theme.spacing(2)}px ${theme.spacing(0)}px ${theme.spacing(3)}px ${theme.spacing(2)}px`
	}
})

class FullScreenModal extends React.Component {
	onPressClose = () => {
		const payload = {
			show: false
		}
		this.props.appActions.hideModal(payload)
	}

	navigate = () => {
		const payload = {
			show: false
		}
		this.props.appActions.hideModal(payload)
	}

	render() {
		const { classes, dialog } = this.props
		const { show, content } = dialog

		const header = content === 'FAQ' ? 'FAQ' : 'Terms & Conditions'

		const body =
			content === 'FAQ' ? (
				<div
					style={{
						display: 'flex',
						right: '10%',
						background: 'white'
					}}>
					<iframe
						title='Terms and Conditions'
						src='https://develop.d2ks05ifrwgl6a.amplifyapp.com/faq.html'
						frameBorder='0'
						height='500vh'
						width='100%'
					/>
				</div>
			) : (
				<div
					style={{
						display: 'flex',
						right: '10%',
						background: 'white'
					}}>
					<iframe
						title='FAQ'
						src='https://develop.d2ks05ifrwgl6a.amplifyapp.com/termsandconditions.html'
						frameBorder='0'
						height='500vh'
						width='100%'
					/>
				</div>
			)

		return (
			<Dialog
				fullWidth={true}
				maxWidth='lg'
				open={show}
				onClose={this.onPressClose}
				aria-labelledby='dialog-error-dialog'
				className={classes.root}>
				<Box component='div' className={classes.errorTopBox}>
					<div className={classes.errorTopLogo}>
						<img className={classes.logo} src={logo} alt='wedawaru-logo' />
					</div>
					<div className={classes.errorTopTitle}>{header}</div>
					<div className={classes.errorTopClose}>
						<span onClick={this.onPressClose}>
							<Close />
						</span>
					</div>
				</Box>
				{/* error-heading-end */}

				{body}
				{/* footer action */}
				<DialogActions>
					<div className={classes.errorFooterBox}>
						<Widgets.ButtonBox
							type='button'
							disabled={false}
							label='Close'
							size='large'
							onClick={() => this.navigate()}
						/>
					</div>
				</DialogActions>
			</Dialog>
		)
	}
}

function mapStateToProps(state) {
	return {
		dialog: state.appReducer.dialog
	}
}

function mapDispatchToProps(dispatch) {
	return {
		appActions: bindActionCreators(appActions, dispatch),
		authActions: bindActionCreators(authActions, dispatch)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles, { withTheme: true })(FullScreenModal))
