//import _ from 'lodash';
//import moment, { unitOfTime } from 'moment';

const MAX_CHAR_LENGHT_DESPENSARY_NAME = 45
const MAX_CHAR_LENGHT_DOCTOR_DISPLAY_NAME = 26
const MAX_CHAR_LENGHT_DOCTOR_DISPLAY_NAME_IN_SINHALA = 23
const MAX_CHAR_LENGHT_DOCTOR_EDUCCATION_TEXT = 250

const Helpers = function () {
	const validateEmail = (name, address) => {
		return !name || /^[a-zA-Z]{1}[a-zA-Z0-9\s\-_]{2,19}$/.test(address)
	}

	const feildTextLeft = (feildname, chars) => {
		let leftText = null
		if (feildname === 'dispensaryName') {
			leftText = MAX_CHAR_LENGHT_DESPENSARY_NAME - chars
			return leftText < 0 ? 0 : leftText
		} else if (feildname === 'displayName') {
			leftText = MAX_CHAR_LENGHT_DOCTOR_DISPLAY_NAME - chars
			return leftText < 0 ? 0 : leftText
		}else if (feildname === 'displayNameInSinhala') {
			leftText = MAX_CHAR_LENGHT_DOCTOR_DISPLAY_NAME_IN_SINHALA - chars
			return leftText < 0 ? 0 : leftText
		} else if (feildname === 'education') {
			leftText = MAX_CHAR_LENGHT_DOCTOR_EDUCCATION_TEXT - chars
			return leftText < 0 ? 0 : leftText
		}
	}

	const isEmpty = obj => {
		return Object.keys(obj).length === 0
	}

	return { validateEmail, feildTextLeft, isEmpty }
}

export default Helpers()
