import * as types from './types'
import * as authTypes from '../../auths/redux/types'

const defaultState = {
	step: 1,
	title: 'Add an Ayurvedic Doctor',
	conditionTerms: false,
	formInputs: {
		multiLogin: false,
		doctorTitle: '',
		initials: '',
		lastName: '',
		displayName: '',
		SLMCRegNo: '',
		specility: '',
		doctorFee: '',
		education: '',
		mobile: '',
		email: '',
		emailConfirm: '',
		password: ''
	},
	formHints: {
		displayName: '',
		education: ''
	},
	doctorOtp: false,
	awsDoctorCreationResponse: {},
	doctorOtpValidationResponse: {},
	doctorNic: { front: {}, back: {} },
	doctorMetaData: {},
	sendEmailInvitationResponse: {
		uuid: '',
		response: {}
	},
	addNewDoctorResponse: {
		uuid: '',
		response: {}
	},
	assignDoctorToDispensaryResponse: {
		uuid: '',
		response: {}
	},
	imageUploadResponse: {},
	displayName: null,
	createAWSDoctorResponse: {
		uuid: '',
		status: '',
		form: ''
	},
	confirmUserResponse: {
		uuid: '',
		status: '',
		form: ''
	},
	invitationDoctorResponse: {
		uuid: '',
		response: {}
	},
	doctorSignInResponse: {
		uuid: '',
		status: ''
	}
}

const doctorReducer = (state = defaultState, action) => {
	switch (action.type) {
		case types.SET_DOCTOR_FORM_FIELD_VALUE:
			return {
				...state,
				formInputs: {
					...state.formInputs,
					[action.payload.fieldname]: action.payload.value
				}
			}

		case types.SET_DOCTOR_FORM_FIELD_HINT:
			return {
				...state,
				formHints: {
					...state.formHints,
					[action.payload.fieldname]: action.payload.value
				}
			}

		case types.UPDATE_DOCTOR_CURRENT_FORM_STEP:
			return {
				...state,
				step: action.payload
			}

		case types.UPDATE_DOCTOR_CONDITION_AND_TERMS:
			return {
				...state,
				conditionTerms: action.payload
			}

		case types.SET_DOCTOR_METADATA:
			return {
				...state,
				doctorMetaData: action.payload
			}
		case types.SEND_EMAIL_INVITATION_RESPONSE:
			return {
				...state,
				sendEmailInvitationResponse: action.payload
			}
		case types.SUBMIT_OWNER_DOCTOR_TO_API_RESPONSE:
			return {
				...state,
				addNewDoctorResponse: action.payload
			}
		case types.ASSIGN_DOCTOR_TO_DISPENSARY_RESPONSE:
			return {
				...state,
				assignDoctorToDispensaryResponse: action.payload
			}
		case types.UPLOAD_IMAGES_TO_S3_RESPONSE:
			return {
				...state,
				imageUploadResponse: action.payload
			}
		case types.CREATE_AWS_DOCTOR_RESPONSE:
			return {
				...state,
				createAWSDoctorResponse: action.payload
			}
		case types.AWS_DOCTOR_CONFIRM_OTP_RESPONSE:
			return {
				...state,
				confirmUserResponse: action.payload
			}
		case types.SUBMIT_DOCTOR_TO_API_RESPONSE:
			return {
				...state,
				invitationDoctorResponse: action.payload
			}
		case types.DOCTOR_SIGN_IN_RESPONSE:
			return {
				...state,
				doctorSignInResponse: action.payload
			}
		case types.SET_DOCTOR_INFO_TO_FORM:
			return {
				...state,
				formInputs: action.payload
			}
		case types.RESET_DOCTOR_STATE:
			return {
				...state,
				defaultState
			}
		case authTypes.CLEAR_TEMP_DATA:
			return {
				...state,
				formInputs: defaultState.formInputs,
				step: 1
			}
		default:
			return state
	}
}

export default doctorReducer
