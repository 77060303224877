import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Container, Box } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import dispensaryIcon from '../../images/dispensary-icon.png'
import doctorIcon from '../../images/doctor-icon.png'
import numONE from '../../images/num-one.svg'
import numTWO from '../../images/num-two.svg'

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1
	},
	cardPhoto: {
		width: 'auto',
		height: '70px',
		paddingLeft: '4em',
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	},
	cardHead: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		verticalAlign: 'center',
		// border:'1px solid red',
		marginLeft: `${theme.spacing(-0.33)}em`
	},
	bulletNo: {
		width: '30px',
		height: '30px',
		textAlign: 'center',
		border: '1px solid #f5f5f5',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		verticalAlign: 'center',
		fontFamily: 'Roboto Bold,sans-serif',
		fontSize: '15px',
		fontWeight: 'Bolder',
		backgroundColor: '#FFF',
		borderRadius: '50%',
		margin: `${theme.spacing(0.5)}px`,
		boxShadow: '1px 1px 10px 0px rgba(0, 0, 0, 0.1)'
	},
	// bulletIcon: {
	//     width: '36px',
	//     height: '36px',
	//     marginLeft: '-5.5em'
	// },

	bulletTitle: {
		padding: '5px 0px 10px 1px',
		verticalAlign: 'center',
		textAlign: 'center',
		fontFamily: 'Roboto Condensed,sans-serif',
		color: '#0e2c39',
		fontSize: '20px',
		[theme.breakpoints.down('md')]: {
			fontSize: '17px'
		}
	},
	cardPara: {
		fontFamily: 'Roboto Light,sans-serif',
		color: '#707070',
		fontSize: '16px',
		[theme.breakpoints.down('md')]: {
			fontSize: '14px'
		}
	},
	cardBody: {
		border: '0px solid #ccc',
		marginTop: '0.2em',
		marginBottom: '1em'
	}
}))

const StepupPostCard = props => {
	const classes = useStyles()
	const { id } = props
	const icon = id == 1 ? dispensaryIcon : id==2 ? doctorIcon : doctorIcon
	const bulletTitle = id == 1 ? 'Download WEDAWARU App' : id==2 ? 'Book an Appointment' : id==3? 'Meet the Ayurvedic Doctor':''; 
	// const bulletPoint = id === 1 ? numONE : numTWO;
	const bulletPoint = id == 1 ? '1' : id==2 ? '2' : '3'

	return (
		<Box component='div' className={`${classes.root} signup_card_box`}>
			{/* <img className={`${classes.cardPhoto} card_photo`} src={icon} alt='icon' /> */}
			<div className='card_info'>
				<div className={classes.cardHead}>
					{/* <img className={classes.bulletIcon} src={bulletPoint} alt="1" /> */}
					{/* <span className={classes.bulletNo}>{bulletPoint}</span> */}
					<span className={classes.bulletTitle}>{bulletTitle}</span>
				</div>

				<div className={classes.cardBody}>
					{id == 1 ? (
						<span className={classes.cardPara}>
							By downloading and signing up on the
							<br />
							he WEDAWARU app, you can easily book and
							<br />
							manage Ayurvedic Doctor appointments on your
							<br/>
							mobile.
						</span>
					) : id == 2 ? (
						<span className={classes.cardPara}>
							Find an Ayurvedic Doctor at a time of the 
							<br />
							day that suites you. Pay a booking fee  <br />
							secure the appointment. This can be <br/>
							changed or cancelled using the app.
						</span>
					): id == 3 ?(
						<span className={classes.cardPara}>
							Find an Ayurvedic Doctor at a time of the uuuuu
							<br />
							day that suites you. Pay a booking fee  <br />
							secure the appointment. This can be <br/>
							changed or cancelled using the app.
						</span>
					):''}
				</div>
				<div className='card_footer'></div>
			</div>
		</Box>
	)
}
export default StepupPostCard
