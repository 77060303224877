import React, { Component, Fragment, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { put, call, select } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import {
    Box,
    Container,
    Toolbar,
    AppBar, Typography,
    Button
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import brandLogo from '../../../images/wedawaru_logo_lg.png'
import Controls from 'containers/forms/components/controls'
import Widgets from 'components/widgets'
import * as actions from './redux/actions';
// import { APIStatusCode, AppConstants } from '../../../constants';
import PropTypes from 'prop-types';
import FormControlsLayout from 'containers/forms/components/FormControlsLayout'
import FormControlEnd from 'containers/forms/components/FormControlEnd'
import { NAVBAR_ROUTES } from 'constants/NavConstants';
import * as kioskActions from './redux/actions';
import { bindActionCreators } from 'redux'
import { CenterFocusStrong } from '@material-ui/icons';
import { Sms } from '@material-ui/icons';
import { GlobalSubscriptionListner } from '../../../components/appsyncSubscription/AppsyncSubscription';
import { getUpdatedAppoiments } from '../../../utils/';

const MY_ID = 100



const styles = theme => ({
    root: {
        flexGrow: 1,

        [theme.breakpoints.up('lg')]: {
            maxWidth: '1300px'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        // TODO zoom
        [theme.breakpoints.between('md', 'lg')]: {
            //backgroundColor:'red',
            zoom: '0.87'
        },

    },

    navbarStyle: {
        background: '#006837',
        color: '#0e2c39'
    },
    brandLogoIcon: {
        width: 'auto',
        height: 'auto',
        marginLeft: '-1em',
        [theme.breakpoints.down('sm')]: {
            //flexGrow: 1,
        }
    },
    brandLogoIcon2: {
        width: '100px',
        height: '100px',
        marginLeft: '-1em',
        [theme.breakpoints.down('sm')]: {
            //flexGrow: 1,
        }
    },
    title: {
        fontSize: 60,
        color: '#FFFFFF',
        fontWeight: 'bold'
    },
    headerText: {
        fontSize: 60,
        color: '#0E2C39',
        fontWeight: 'bold',
        alignItems: "center",
    },
    headerOptions: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-evenly'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center'
    },
    container: {
        marginTop: theme.spacing(3),
    },
    containerThankyou: {
        marginTop: theme.spacing(6)
    },
    gridSpacing: {
        marginBottom: theme.spacing(3)
    },
    formLabelWrapperRight: {
        textAlign: 'right',
    },
    formLabelWrapperLeft: {
        textAlign: 'left',
    },
    formLabelWrapperCenter: {
        textAlign: 'center',
    },
    formLabel: {
        fontSize: 25,
        fontWeight: 'bold',
        lineHeight: '25px',
    },
    formLabelThanks: {
        fontSize: 30,
        fontWeight: 'bold',
        lineHeight: '60px',
    },
    formLabelToday: {
        fontSize: 50,
        fontWeight: 'bold',
        lineHeight: '60px',
    },
    textFiled: {
        width: '100%',
        border: '2px solid #159782',
        color: 'black',
        '& .MuiOutlinedInput-root': {
            borderRadius: '0px',
            '& .MuiOutlinedInput-input': {
                fontFamily: 'Roboto Medium,sans-serif',
                //fontSize: 'clamp(13px,2vw,20px)'
            },

            '& fieldset': {
                borderColor: 'none',
                border: 'none'
            },
            '&:hover fieldset': {
                //borderColor: themeGreenColor,
                border: 'none'
            },
            '&.Mui-focused fieldset': {
                //borderColor: mingColor, * when focus feild
                borderColor: 'none',
                border: 'none'
            }
        },
        "&::placeholder": {
            textOverflow: "ellipsis !important",
            color: "blue"
        }
    },
    numberWrapper: {
        background: '#FD9976',
        width: 350,
        height: 240,
        borderRadius: 160,
        color: '#ffffff',
        textAlign: 'center',
        fontSize: 180,
        // marginBottom: 25,
        // marginLeft: 20,
        fontWeight: 'bold'
    },
    nextButton: {
        width: 350,
        height: 80,
        fontSize: 60,
        textTransform: 'capitalize',
        borderRadius: 5,
    },
    closeButton: {
        background: '#707070',
        color: '#ffffff',
        fontSize: 25,
        textTransform: 'capitalize'

    },
    countTimer: {
        fontWeight: '400',
        fontSize: '15px',
        marginLeft: '5px',
    },
    kioskFooter: {
        background: '#0E2C39',
        minHeight: 60,
        textAlign: 'center',
        color: '#ffffff',
        fontWeight: '500',
        position: 'fixed',
        width: '100%',
        bottom: 0,
        padding: '7px 0'
    },
    footerText: {
        fontSize: 30,
    },
    icon: {
        width: 40,
        height: 100,
        fontSize: 50,
        display: "block",
        float: "none",
    }
})

class NowServingLayout extends Component {

    constructor(props) {
        super(props)


        this.state = {
            nowServing: 0,

            appointments: [],
        };
    }

    focusListener = () => {
        // const { selectedDate } = this.state
        // if (!checkDateIsToday(selectedDate)) {
        //     this.forceViewToday();
        // }

    };

    componentDidMount() {
        const payload = {
            "attributes": {
                "email": this.props.portalState.doctorsList[0].email
            }
        }
        this.props.appointmentsActions.requestAppointments(payload)

        GlobalSubscriptionListner.subscribeSlotUpdates(MY_ID, this.onSlotUpdate)
        // AppState.addEventListener("change", this._handleAppStateChange);
    }

    componentDidUpdate(prevProps) {
        if (this.props.appointmentState.appointments.length > 0) {
            const slots = this.props.appointmentState.appointments[0].slots
            const nowSlot = slots.filter(slot => slot.status == 2 || slot.status == 3);
            // const nowServing = checkedIn.fin
            const newNowServing = nowSlot[0] ? nowSlot[0].slot_id : 0
            const newNowServingDisplayName = nowSlot[0] ? nowSlot[0].patient.display_name : 'Waiting for patient'
            const { nowServing } = this.state;
            if (nowServing != newNowServing) {
                this.setState({
                    nowServing: newNowServing,
                    newNowServingDisplayName: newNowServingDisplayName
                })
            }
        }
    }

    onSlotUpdate = doctorUpdatedSlots => {
        const { date } = doctorUpdatedSlots
        // const selectedDateStr = serverDateToString(this.state.selectedDate)
        // if (date !== selectedDateStr) return

        // const todayDateStr = serverDateToString(new Date())
        // if (date === todayDateStr) return

        const updatedApoiments = getUpdatedAppoiments(this.state, doctorUpdatedSlots)
        this.setState({ appointments: updatedApoiments })

    }

    componentWillUnmount() {
        // if (this.timeout) {
        //   clearTimeout(this.timeout)
        // }
    }

    render() {
        const { classes, lastBookAppointment, portalState } = this.props;
        const firstName = ""

        const { nowServing, newNowServingDisplayName } = this.state
        const nowServingNumber = nowServing ? nowServing : 0;
        const displayName = portalState.doctorsList[0]['display_name']

        return (
            <>
                <AppBar position='fixed' className={classes.navbarStyle}>
                    <Container maxWidth='lg' disableGutters={false}>
                        <Toolbar className={classes.toolbar}>
                            <Box component='span'>
                                <img src={brandLogo} className={classes.brandLogoIcon} alt='Logo' />
                            </Box>
                            <div className={classes.headerOptions}>
                                <Box>
                                    <Typography className={classes.title}>{displayName}</Typography>
                                </Box>
                                {/* <Box>
                                    <Typography className={classes.title}>ඔබගේ SMS බලන්න!</Typography>
                                </Box> */}
                            </div>
                        </Toolbar>
                    </Container>
                </AppBar>

                <Container className={classes.containerThankyou}>
                    <Grid container spacing={3}>
                        <Grid item xs={3} sm={1} className={classes.formLabelWrapperLeft}>
                        </Grid>
                        <Grid item xs={30} sm={10} className={classes.formLabelWrapperCenter}>
                            <Box>
                                {/* <Typography className={classes.headerText}>{firstName},</Typography> */}
                                <Typography className={classes.headerText}>Now Serving Number</Typography>
                                {/* <Typography className={classes.headerText}>ඔබගේ අංකය තහවුරුයි!</Typography> */}
                            </Box>
                        </Grid>
                        {/* <Grid item xs={3} sm={1} className={classes.formLabelWrapperLeft}>
                    </Grid> */}
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                        </Grid>
                        <Grid item xs={12} sm={4} className={classes.formLabelWrapperCenter}>
                            <Box className={classes.numberWrapper} mt={4}>
                                {/* {lastBookAppointment.slot_id} */}
                                {nowServingNumber}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                        </Grid>

                    </Grid>

                    <Grid container spacing={1}>
                        <Grid className={classes.headerText} item xs={12} sm={12}>
                            <marquee width="100%" direction="left" height="100%">
                                Patient name: {newNowServingDisplayName}
                            </marquee>
                        </Grid>
                    </Grid>
                    {/* <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <Button variant="contained"
                                    className={classes.nextButton} color="primary"
                                    onClick={this.props.appointmentsActions.navigateToKiosk}>
                                    Thank You
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                        </Grid>
                    </Grid> */}
                </Container>

                {/* <Box className={classes.kioskFooter}>
                <Typography className={classes.footerText}>Medical Center - Dr U P Daniel</Typography>
            </Box> */}

            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        appointmentState: state.appointmentsReducer,
        lastBookAppointment: state.appointmentsReducer.lastBookAppointment,
        portalState: state.portalReducer,

    }
}

function mapDispatchToProps(dispatch) {
    return {
        appointmentsActions: bindActionCreators(kioskActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(NowServingLayout))
