import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Box, Container, Grid, Button } from '@material-ui/core'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import { ArrowBack, Close } from '@material-ui/icons'
import Controls from 'containers/forms/components/controls'
import Widgets from 'components/widgets'

import { NAVBAR_ROUTES } from 'constants/NavConstants'

const useStyles = makeStyles(theme => ({
	contactFormBox: {
		flex: 1
	},
	formsBody: {
		//border: '1px dotted green',
		width: '100%',
		margin: `${theme.spacing(0)}px ${theme.spacing(0)}px`,
		'& .MuiFormControl-root': {
			width: '100%',
			marginBottom: theme.spacing(0)
		}
	},
	formCtrlBox: {
		margin: `${theme.spacing(0)}px ${theme.spacing(0)}px`
	},
	resetTopBox: {
		minHeight: '3.5rem',
		padding: `${theme.spacing(1)}px ${theme.spacing(0)}px`,
		[theme.breakpoints.down('xs')]: {
			paddingTop: theme.spacing(5)
		}
	}
}))

const ContactForm = props => {
	const classes = useStyles()
	const theme = useTheme()
	const [disabled, setIsDisabled] = useState(true)
	const [sendBtnAttr, setSendBtnAttr] = useState({ variantType: 'outlined', themeColor: 'secondary' })
	const [clear, setClear] = useState(false)
	const { data, handleSubmit, handleInputChange, errors, resetForm } = props

	function validateEmail(email) {
		if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
			return false
		} else {
			return true
		}
	}

	const validateForm = () => {
		setIsDisabled(data.fullname.length > 0 && validateEmail(data.email) && data.message.length > 0)

		if (data.fullname.length > 0 && validateEmail(data.email) && data.message.length > 0) {
			setSendBtnAttr({ variantType: 'contained', themeColor: 'primary' })
		} else {
			setSendBtnAttr({ variantType: 'outlined', themeColor: 'secondary' })
		}
	}

	const clearForm = () => {
		setClear(data.fullname.length > 0 || validateEmail(data.email) || data.message.length > 0)
	}

	useEffect(() => {
		validateForm()
	}, [data.email, data.fullname, data.message])

	useEffect(() => {
		clearForm()
	}, [data.email, data.fullname, data.message])

	return (
		<Box
			component='div'
			flexDirection='column'
			justifyContent='center'
			alignItems='center'
			className={classes.contactFormBox}>
			<Grid container>
				<Grid item xs={12}>
					<Box
						component='div'
						display='flex'
						flexDirection='column'
						justifyContent='center'
						alignItems='flex-end'
						className={classes.resetTopBox}>
						<Button disabled={!clear} onClick={resetForm}>
							RESET
						</Button>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<form onSubmit={handleSubmit} autoComplete='off'>
						<Box
							component='div'
							display='flex'
							flexDirection='column'
							justifyContent='center'
							className={classes.formsBody}>
							<Box className={classes.formCtrlBox}>
								<Controls.InputText
									label='FULL NAME'
									type='text'
									themeColor='secondary'
									name='fullname'
									value={data.fullname}
									onChange={handleInputChange}
									error={errors.fullname}
								/>
							</Box>
							<Box className={classes.formCtrlBox}>
								<Controls.InputText
									label='MOBILE'
									type='mobile'
									themeColor='secondary'
									name='mobile'
									value={data.mobile}
									onChange={handleInputChange}
									error={errors.mobile}
								/>
							</Box>
							<Box className={classes.formCtrlBox}>
								<Controls.InputText
									label='EMAIL'
									type='email'
									themeColor='secondary'
									name='email'
									value={data.email}
									onChange={handleInputChange}
									error={errors.email}
								/>
							</Box>
							<Box className={classes.formCtrlBox}>
								<Controls.MuiltInputBox
									label='MESSAGE'
									type='text'
									themeColor='secondary'
									value={data.message ? data.message : ''}
									autoCorrect={false}
									row={10}
									name='message'
									limit={300}
									onChange={handleInputChange}
									error={errors.message}
								/>
							</Box>
							{/* button send */}
							<Box
								className={classes.formCtrlBox0}
								component='div'
								display='flex'
								flexDirection='row'
								justifyContent='center'>
								<Widgets.ButtonBox
									type='submit'
									disabled={!disabled}
									label='SEND MESSAGE'
									variantType={sendBtnAttr.variantType}
									themeColor={sendBtnAttr.themeColor}
									size='large'
								/>
							</Box>
						</Box>
					</form>
					{/* end form section */}
				</Grid>
			</Grid>
		</Box>
	)
}

export default ContactForm
