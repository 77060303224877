import React, { useState, useEffect, Fragment } from 'react'
import { Box, Container } from '@material-ui/core'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import { ArrowBack, Close } from '@material-ui/icons'
import brandLogo from 'images/wedawaru_logo_lg.png'

const useStyles = makeStyles(theme => ({
	successToolBox: {
		boxSizing: 'border-box',
		//borderBottom: '1px dotted #ccc',
		flexGrow: 1,
		display: 'grid',
		gridTemplateColumns: '1fr auto',
		padding: '1rem 0.5rem'
	},
	brandLogoIcon: {
		width: 'auto',
		height: '42px',
		marginLeft: '1em',
		[theme.breakpoints.down('sm')]: {
			//flexGrow: 1,
		}
	},
	toolBoxRight: {
		marginRight: theme.spacing(0),
		marginTop: theme.spacing(-2),
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'flex-start',
		cursor: 'pointer',
		color: '#39CFBA',
		'&:hover': {
			color: '#159782'
		}
	},
	toolBoxIcon: {
		marginRight: theme.spacing(-1)
	},

	successGreetingHeading: {
		padding: '3rem 2rem',
		//borderBottom: '1px dotted #ccc',
		fontFamily: 'Roboto light,sans-serif',
		fontSize: 'clamp(18px,2.3vw,26px)',
		color: '#0E2C39'
	}
}))

const SuccessHeader = props => {
	const classes = useStyles()
	const theme = useTheme()
	const { showLogo = null, username = null } = props

	const onPressPressedClose = () => {
		const { exitForm } = props
		exitForm()
		// alert('onPressed close')
	}

	return (
		<Box component='div'>
			<Box className={classes.successToolBox}>
				<div className={classes.toolBoxLeft}>
					<Box component='div' className={classes.toolLogo}>
						{showLogo && <img src={brandLogo} className={classes.brandLogoIcon} alt='Logo' />}
					</Box>
				</div>
				<div className={classes.toolBoxRight}>
					<span onClick={onPressPressedClose}>
						<Close className={classes.toolBoxIcon} />
					</span>
				</div>
			</Box>
			<Box className={classes.successGreetingHeading}>
				<div>{username && `Hello ${username}`}</div>
			</Box>
		</Box>
	)
}

export default SuccessHeader
