import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { withStyles } from '@material-ui/core/styles'
import { Box, Grid, Container } from '@material-ui/core'

import * as dispensaryActions from '../forms/Dispensary/redux/actions'
import * as actions from './redux/actions'
import * as authActions from '../forms/auths/redux/actions'

import AccessGrantInfoBox from 'containers/portalweb/components/AccessGrantInfoBox'
import PortalTableViewBox from 'containers/portalweb/components/PortalTableViewBox'

// dummy data
import { NAVBAR_ROUTES } from 'constants/NavConstants'
import { goToStep } from 'containers/forms/Dispensary/DispensaryLayout'

const withMediaQuery = (...args) => Component => props => {
	const mediaQuery = useMediaQuery(...args)
	return <Component mediaQuery={mediaQuery} {...props} />
}

const styles = theme => ({
	root: {
		//border: '1px dotted pink',
		width: '100%',
		[theme.breakpoints.between('md', 'lg')]: {
			zoom: '0.90'
			// minHeight: `calc(100vh + calc(100vh * .33))`,
		}
	},
	portalHeadingBox: {
		//border: '1px dotted #333',
		justifyContent: 'center',
		alignItems: 'center',
		// fontSize: 'clamp(20px,calc(1vw + 1.6rem), 50px)',
		// fontFamily: 'Roboto Condensed ,sans-serif',
		fontSize: 'clamp(24px,5vw,50px)',
		fontFamily: 'Roboto Condensed ,sans-serif',
		color: '#0e2c39',
		padding: `${theme.spacing(3)}px ${theme.spacing(0)}px`,
		[theme.breakpoints.down('xs')]: {
			padding: `${theme.spacing(2)}px ${theme.spacing(0)}px`,
			fontSize: '24px'
		}
	},
	potalAccessInfoBox: {
		borderBottom: '0px solid #eee',
		[theme.breakpoints.down('xs')]: {
			borderBottom: '1px solid #eee'
		}
	},
	portalTableBox: {
		// border: '1px solid #f5f5f5'
	}
})

class PortalWebPage extends Component {
	constructor(props) {
		super(props)
		this.state = {
			dispensaryList: [],
			doctorList: [],
			staffList: [],
			showSuccessRegistration: false
		}
	}

	componentDidMount() {
		this.props.actions.getDispensaryDetailsRequest()
		this.props.actions.getDoctorListRequest()
		this.props.actions.getStaffListRequest()
		this.props.dispensaryActions.updateFormStep(1)
		this.props.authActions.clearTempData()
	}

	componentDidUpdate(prevProps) {
		const { history, dispensaryActions, authState } = this.props
		const { currentUserInfo } = authState

		const prevDispensaryResponse = prevProps.portalState.dispensaryDetails
		const currentDispensaryResponse = this.props.portalState.dispensaryDetails

		const prevDoctorList = prevProps.portalState.doctorsList
		const currentDoctorList = this.props.portalState.doctorsList

		const prevStaffList = prevProps.portalState.staffList
		const currentStaffList = this.props.portalState.staffList

		if (prevDispensaryResponse !== currentDispensaryResponse) {
			if (currentDispensaryResponse.action === goToStep.DISPENSARY_DETAILS_FORM) {
				dispensaryActions.updateFormStep(goToStep.DISPENSARY_DETAILS_FORM)
				dispensaryActions.flowlessRegistration(true)
				history.push(NAVBAR_ROUTES.DESPENSARY_SIGNUP_FORM)
			}
		}

		if (prevDispensaryResponse !== currentDispensaryResponse) {
			if (currentDispensaryResponse) {
				this.setState({ dispensaryList: [currentDispensaryResponse.data] }, () =>
					console.log('disp')
				)
			}
		}

		if (prevDoctorList !== currentDoctorList) {
			if (currentDoctorList) {
				this.setState({ doctorList: currentDoctorList }, () => {
					let exists = currentDoctorList.some(item => item.verified === true)
					if (!exists) {
						this.setState({ showSuccessRegistration: false })
					} else {
						this.setState({ showSuccessRegistration: true })
					}
				})
			}
		}

		if (prevStaffList !== currentStaffList) {
			if (currentStaffList) {
				this.setState({ staffList: currentStaffList })
			}
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.authState.loggedIn) {
			if (this.props.location.pathname !== nextProps.location.pathname) {
				this.props.actions.getDispensaryDetailsRequest()
				this.props.actions.getDoctorListRequest()
				this.props.actions.getStaffListRequest()
			}
		}
	}

	render() {
		const { classes, theme, history, contactState, mediaQuery } = this.props
		const isMobile = mediaQuery

		const { dispensaryList, doctorList, staffList, showSuccessRegistration } = this.state

		const viewBoxData = { dispensaryList, doctorList, staffList }

		return (
			<>
				<Container maxWidth='md' disableGutters={true} component='div'>
					<Grid container direction='column' justifyContent='flex-start' alignItems='flex-start'>
						<div className={classes.root}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Box
										component='div'
										display='flex'
										flexDirection='row'
										justifyContent='center'
										alignItems='center'
										className={classes.portalHeadingBox}>
										Hospital Owner Portal
									</Box>
								</Grid>
								<Grid item xs={12}>
									<Box component='div' className={classes.potalAccessInfoBox}>
										{/* TODO : show this section conditionaly */}
										{showSuccessRegistration ? <AccessGrantInfoBox /> : null}
									</Box>
								</Grid>
								<Grid item xs={12}>
									<Box component='div' className={classes.portalTableBox}>
										<PortalTableViewBox history={history} data={viewBoxData} />
									</Box>
								</Grid>
							</Grid>
						</div>
					</Grid>
				</Container>
				{/* <CallNowFooterSticky history={history} /> */}
			</>
		)
	}
}

function mapStateToProps(state) {
	return {
		authState: state.authReducer,
		portalState: state.portalReducer,
		contactState: state.contactState,
		doctorState: state.doctorState
	}
}

function mapDispatchToProps(dispatch) {
	return {
		dispensaryActions: bindActionCreators(dispensaryActions, dispatch),
		actions: bindActionCreators(actions, dispatch),
		authActions: bindActionCreators(authActions, dispatch)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles, { withTheme: true })(withMediaQuery('(max-width:425px)')(PortalWebPage)))
