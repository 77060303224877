import React, { Component, Fragment } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import PlacesAutocomplete from 'react-places-autocomplete'
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-places-autocomplete'

import { Box, Grid } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import * as dispensaryActions from './redux/actions'
import GoogleMaper from '../components/widgets/GoogleMapper'
import FormControlsLayout from 'containers/forms/components/FormControlsLayout'
import Controls from 'containers/forms/components/controls'
import Widgets from 'components/widgets'
import Helpers from 'utils/Helpers'
import Validators from 'utils/Validators'
import FormControlEnd from 'containers/forms/components/FormControlEnd'

const styles = theme => ({
	root: {
		// flexGrow: 1,
		//border: '0px dotted #ccc'
	},

	googleMapRow: {
		position: 'relative',
		border: '0px dotted blue',
		boxSizing: 'border-box'
	},

	googleMapBox: {
		position: 'relative',
		border: '1px solid #f5f5f5',
		width: '100%',
		maxHeight: 'auto',
		boxSizing: 'border-box',
		margin: '-3.33rem 0rem 2rem 0rem',
		padding: '0rem'
	},

	formEndBox: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		margin: `${theme.spacing(5)}px ${theme.spacing(0)}px`,
		padding: `${theme.spacing(3)}px ${theme.spacing(0)}px`,
		//borderTop: '1px dotted red',

		[theme.breakpoints.down('sm')]: {
			//padding: '1rem 0rem',
			padding: `${theme.spacing(0)}px ${theme.spacing(0)}px`,
			//marginBottom: `${theme.spacing(5)}px`,
			margin: `${theme.spacing(3)}px ${theme.spacing(0)}px`
		}
	},

	nthChild: {
		[theme.breakpoints.down('xs')]: {
			//marginTop: '1.5rem',
			marginTop: `${theme.spacing(0.5)}px`
		}
	}
})

class DispensaryDetailsForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			errors: {},
			showMap: false,
			lat: 6.9270786,
			lng: 79.861243,
			address: '',
			blurred: true,
			area: ''
		}
	}

	componentDidMount() {
		if (this.props.update) {
			this.setState(
				{
					lat: this.props.data.location.lat,
					lng: this.props.data.location.lng,
					address: this.props.data.address,
					area: this.props.data.city
				},
				() => {
					console.log('')
				}
			)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.dispensaryState.formInputs.uuid !== prevProps.dispensaryState.formInputs.uuid) {
			if (this.props.update) {
				this.setState({
					lat: this.props.data.location.lat,
					lng: this.props.data.location.lng,
					address: this.props.data.address,
					area: this.props.data.city
				})
			}
		}
	}

	validator = () => {
		const { data } = this.props
		const { errors, address } = this.state
		const { dispensaryName, businessRegNo, phoneNo, district, serviceCategory, cardReader } = data

		errors.dispensaryName = Validators.required(dispensaryName) || Validators.minLength(dispensaryName, 6)
		errors.businessRegNo = Validators.required(businessRegNo) || Validators.minLength(businessRegNo, 3)
		errors.address = Validators.required(address)
		errors.phoneNo = Validators.required(phoneNo)
		errors.district = Validators.validateOption(district)
		errors.serviceCategory = Validators.validateOption(serviceCategory)
		errors.cardReader = Validators.validateOption(cardReader)

		this.setState(prevState => ({
			errors: errors
		}))
		return Object.values(errors).every(err => err === '' || err === undefined)
	}


	openGoogleMap = e => {
		const { showMap, blurred } = this.state
		this.setState({
			showMap: !showMap,
			blurred: false
		})
	}

	closeGoogleMap = e => {
		this.setState({
			showMap: false
		})
	}

	closeMap = () => {
		this.setState({
			showMap: false
		})
	}

	setLocation = (location, address) => {
		const { dispensaryActions } = this.props
		dispensaryActions.setLocationAndAddress(location, address)
		this.handleChange(address)
	}

	updateLocation = (lat, lng) => {
		this.setState({ lat: lat, lng: lng }, () => { })
	}

	handleChange = address => {
		this.setState({ address }, () => console.log(''))
	}

	handleSelect = address => {
		geocodeByAddress(address)
			.then(results => getLatLng(results[0]))
			.then(latLng => {
				this.handleChange(address)
				this.updateLocation(latLng.lat, latLng.lng)
			})
			.catch(error => console.error('Error', error))
	}

	submitDispensaryInfo = e => {
		e.preventDefault()
		const { update } = this.props
		const { dispensaryActions, data, dispensaryState } = this.props
		const { flowless } = dispensaryState

		if (update) {
			let loc = {
				lat: data.location.lat,
				lng: data.location.lng
			}
			const payload = {
				register: false,
				name: data.dispensaryName,
				contact_number: data.phoneNo,
				geo_location: loc,
				address: this.state.address,
				br_number: data.businessRegNo,
				district: data.district,
				service_category: data.serviceCategory,
				card_reader: data.cardReader,
				city: this.state.area
			}
			if (this.validator()) {
				dispensaryActions.updateDispensaryInfo(payload)
			}
		} else {
			let location = {
				lat: this.state.lat,
				lng: this.state.lng
			}

			let payload = {
				flowless: flowless,
				data: {
					register: true,
					name: data.dispensaryName,
					address: this.state.address,
					// phone_number: userInfo.attributes.email,
					contact_number: data.phoneNo,
					geo_location: location,
					br_number: data.businessRegNo,
					district: data.district,
					service_category: data.serviceCategory,
					card_reader: data.cardReader,
					city: this.state.area
					//
					//
				}
			}
			if (this.validator()) {
				dispensaryActions.createDispensaryRequest(payload)
			}
		}
	}

	unsetBlur = () => {
		this.setState({ blurred: true })
	}

	setArea = area => {
		this.setState({ area: area })
	}

	hideElements = () => {
		this.closeMap()
	}

	render() {
		const { classes, theme, history, data, handleInputChange, update } = this.props

		const { showMap, lat, lng, errors, blurred } = this.state

		return (
			<FormControlsLayout>
				<Grid container>
					<Grid container spacing={0} justifyContent='space-between'>
						<Grid item xs={12} sm={5} md={5} lg={5}>
							<Controls.InputText
								label='Name of the Dispensary'
								type='text'
								name='dispensaryName'
								limit={45}
								value={data.dispensaryName}
								onChange={handleInputChange}
								error={errors.dispensaryName}
								charLeft={Helpers.feildTextLeft('dispensaryName', data.dispensaryName.length)}
								onFocus={this.hideElements}
							/>
						</Grid>
						<Grid item xs={12} sm={5} md={5} lg={5}>
							<Controls.InputText
								label='Business Registration No.'
								type='text'
								name='businessRegNo'
								value={data.businessRegNo}
								onChange={handleInputChange}
								error={errors.businessRegNo}
								onFocus={this.hideElements}
							/>
						</Grid>
					</Grid>
					{/* google map address */}
					<Grid item xs={12} className={classes.googleMapRow}>
						<PlacesAutocomplete
							value={this.state.address}
							onChange={this.handleChange}
							onSelect={this.handleSelect}>
							{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
								<div>
									<Controls.GoogleSearchInputBox
										label='Address'
										type='text'
										name='address'
										value={this.state.address}
										// onChange={handleInputChange}
										onFocus={this.openGoogleMap}
										onBlur={this.unsetBlur}
										error={errors.address}
										{...getInputProps({
											placeholder: 'Search Places ...'
										})}
									/>
									<div className='autocomplete-dropdown-container'>
										{loading && <div className='maploading'>Loading...</div>}
										{!blurred
											? suggestions.map(suggestion => {
												const className = suggestion.active
													? 'suggestion-item--active mapdropdiv'
													: 'suggestion-item mapdropdiv'

												const style = suggestion.active
													? {
														backgroundColor: '#fafafa',
														cursor: 'pointer'
													}
													: {
														backgroundColor: '#ffffff',
														cursor: 'pointer'
													}
												return (
													<div className=''>
														<div
															{...getSuggestionItemProps(suggestion, {
																className,
																style
															})}>
															<span>{suggestion.description}</span>
														</div>
													</div>
												)
											})
											: null}
									</div>
								</div>
							)}
						</PlacesAutocomplete>

						{/* ___________________ map container__________________ */}
						{!showMap ? null : (
							<Box className={`${classes.googleMapBox} google_map_Box`}>
								<GoogleMaper
									updateLocation={this.updateLocation}
									getLocation={(location, address) => {
										this.setLocation(location, address)
									}}
									getArea={area => {
										this.setArea(area)
									}}
									googlem={this.props.google}
									center={{ lat: lat, lng: lng }}
									height='400px'
									zoom={15}
									closeMap={this.closeMap}
								/>
							</Box>
						)}
					</Grid>
					{/* -------------------- ----------- ----------------- 
                                Google Map End
                        ---------------------------------------------------
                    */}
					<Grid container spacing={0} justifyContent='space-between'>
						<Grid item xs={12} sm={5} md={5} lg={5}>
							<Controls.InputText
								label='Telephone/Mobile No.'
								type='text'
								name='phoneNo'
								limit={10}
								value={data.phoneNo}
								onChange={handleInputChange}
								error={errors.phoneNo}
								onFocus={this.hideElements}
							/>
						</Grid>
						<Grid item xs={12} sm={5} md={5} lg={5}>
							<Controls.Select
								label='District'
								name='district'
								lblWidth={120}
								value={data.district}
								onChange={handleInputChange}
								// error={errors.district}
								option={[
									{ title: 'Ampara', value: 'Ampara' },
									{ title: 'Anuradhapura', value: 'Anuradhapura' },
									{ title: 'Badulla', value: 'Badulla' },
									{ title: 'Batticaloa', value: 'Batticaloa' },
									{ title: 'Colombo', value: 'Colombo' },
									{ title: 'Galle', value: 'Galle' },
									{ title: 'Gampaha', value: 'Gampaha' },
									{ title: 'Hambantota', value: 'Hambantota' },
									{ title: 'Jaffna', value: 'Jaffna' },
									{ title: 'Kalutara', value: 'Kalutara' },
									{ title: 'Kandy', value: 'Kandy' },
									{ title: 'Kegalle', value: 'Kegalle' },
									{ title: 'Kilinochchi', value: 'Kilinochchi' },
									{ title: 'Kurunegala', value: 'Kurunegala' },
									{ title: 'Mannar', value: 'Mannar' },
									{ title: 'Matale', value: 'Matale' },
									{ title: 'Matara', value: 'Matara' },
									{ title: 'Monaragala', value: 'Monaragala' },
									{ title: 'Mullaitivu', value: 'Mullaitivu' },
									{ title: 'Nuwara Eliya', value: 'Nuwara Eliya' },
									{ title: 'Polonnaruwa', value: 'Polonnaruwa' },
									{ title: 'Puttalam', value: 'Puttalam' },
									{ title: 'Ratnapura', value: 'Ratnapura' },
									{ title: 'Trincomalee', value: 'Trincomalee' },
									{ title: 'Vavuniya', value: 'Vavuniya' }
								]}
							/>
						</Grid>
					</Grid>


					<Grid container spacing={0} justifyContent='space-between'>
						<Grid item xs={12} sm={5} md={5} lg={5}>
							<Controls.Select
								label='Service Category'
								name='serviceCategory'
								lblWidth={120}
								value={data.serviceCategory}
								onChange={handleInputChange}
								error={errors.serviceCategory}
								option={[
									{
										value: 'dispensary',
										title: 'Dispensary'
									},
									{
										value: 'health_check',
										title: 'Health Check'
									}
								]}
							/>
						</Grid>
						<Grid item xs={12} sm={5} md={5} lg={5} className={classes.nthChild}>
							<Controls.Select
								label='Credit Card Reader Machine'
								name='cardReader'
								value={data.cardReader}
								onChange={handleInputChange}
								error={errors.cardReader}
								option={[
									{ value: 'available', title: 'Available' },
									{
										value: 'not_available',
										title: 'Not Available'
									}
								]}
							/>
						</Grid>
					</Grid>

					{/*  FORM_END_SECTION */}
					<Grid item xs={12}>
						{/* <Box className={classes.formEndBox}>
                            <Widgets.ButtonBox
                                type="submit"
                                disabled={false}
                                label="Next"
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={this.handleNext}
                            />
                        </Box> */}
						<FormControlEnd
							btnType='submit'
							label={update ? 'Update' : 'Next'}
							isCheckbox={false}
							disabled={false}
							onChange={() => { }}
							onPressed={this.submitDispensaryInfo}
						/>
					</Grid>
				</Grid>
			</FormControlsLayout>
		)
	}
}

function mapStateToProps(state) {
	return {
		dispensaryState: state.dispensaryReducer
	}
}

function mapDispatchToProps(dispatch) {
	return {
		dispensaryActions: bindActionCreators(dispensaryActions, dispatch)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles, { withTheme: true })(DispensaryDetailsForm))
