import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Box, Grid, useMediaQuery } from '@material-ui/core'
import { FiberManualRecord, Close, CheckCircleOutlineRounded } from '@material-ui/icons'

import base64 from 'react-native-base64'

import * as doctorActions from './redux/actions'
import * as portalActions from '../../portalweb/redux/actions'
import FormControlsLayout from 'containers/forms/components/FormControlsLayout'
import Widgets from 'components/widgets'
import Controls from 'containers/forms/components/controls'
// spacing = 1 (8px)
// rem = 1  (16px)

const withMediaQuery = (...args) => Component => props => {
	const mediaQuery = useMediaQuery(...args)
	return <Component mediaQuery={mediaQuery} {...props} />
}

const styles = theme => ({
	root: {
		flexGrow: 1
	},
	rowBox: {
		//border: '1px dotted #001',
		marginTop: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(1)
		}
	},
	boldHeadingText: {
		fontFamily: 'Roboto Bold,sans-serif',
		fontSize: 'clamp(20px,calc(1vw + 1rem),40px)', // 20px
		color: '#0E2C39'
	},
	boldHeadingMobileText: {
		fontFamily: 'Roboto Bold,sans-serif',
		fontSize: '16px',
		color: '#0E2C39'
	},
	lightHeadingText: {
		fontFamily: 'Roboto Light,sans-serif',
		//fontSize: '30px',
		fontSize: 'clamp(14px,calc(1vw + 0.5rem),30px)', // 20px
		color: '#0E2C39'
	},
	ListBoxText: {
		fontFamily: 'Roboto Light,sans-serif',
		//fontSize: '24px', // 16px
		fontSize: 'clamp(16px,calc(1vw + 0.7rem),24px)', // 20px
		color: '#0E2C39',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-start',
		background: '#FEF9E9',
		padding: `${theme.spacing(3)}px ${theme.spacing(7)}px`,

		[theme.breakpoints.down('xs')]: {
			padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
			margin: '0 calc(-50vw + 50%)'
		}
	},
	ListBoxHeadingText: {
		paddingBottom: theme.spacing(1)
	},
	ListBoxIcon: {
		color: '#0E2C39',
		fontSize: '10px'
	},

	visibilityOptionTop: {
		minHeight: 'calc(100vh - 7rem)'
	},

	addDoctorOption: {
		marginTop: theme.spacing(5)
	},
	sendInviteOption: {
		//border: '1px dotted #001',
		marginTop: theme.spacing(5)
	},

	hiddenOptionBottom: {
		minHeight: '30vh',
		margin: '0rem calc(-50vw + 50%)',
		paddingTop: theme.spacing(5),
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		background: '#F1F9FF',
		[theme.breakpoints.down('xs')]: {
			margin: '0rem calc(-20vw + 10%)'
		},
		[theme.breakpoints.between('md', 'lg')]: {
			margin: '0rem calc(-200vw + 200%)'
		}
	},
	optionButtonBox: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		padding: `${theme.spacing(3)}px ${theme.spacing(0)}px`
	},
	hiddenFormBox: {
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			paddingLeft: theme.spacing(5),
			paddingRight: theme.spacing(5)
		}
	},
	hiddenFormButtonBox: {
		paddingTop: theme.spacing(0.7),
		[theme.breakpoints.down('xs')]: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			marginTop: theme.spacing(-3),
			paddingTop: theme.spacing(0),
			paddingBottom: theme.spacing(5)
		}
	},
	hiddenFormExitButton: {
		width: '100%',
		float: 'right',
		clear: 'right',
		textAlign: 'right',
		cursor: 'pointer',
		'&:hover': {
			color: '#159782'
		}
	},
	hiddenSuccessBox: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		paddingBottom: theme.spacing(3),

		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column'
		}
	},
	hiddenSuccessText: {
		fontFamily: 'Roboto Medium,sans-serif',
		fontSize: 'clamp(20px,calc(2vw + 0.5rem), 40px)', // 20px
		color: '#0E2C39',
		marginRight: theme.spacing(2),
		[theme.breakpoints.down('xs')]: {
			marginRight: theme.spacing(0),
			marginBottom: theme.spacing(1)
		}
	},
	hiddenSuccessIcallingcon: {
		color: '#2699FB',
		fontSize: 'calc(100% + 6rem)',
		[theme.breakpoints.down('xs')]: {
			fontSize: '3rem'
		}
	}
})

class DoctorOptionWizard extends Component {
	constructor(props) {
		super(props)
		this.scrollRef = React.createRef()
		this.state = {
			showHiddenForm: false,
			ownerDoctor: false,
			name: '',
			email: '',
			send200: false
		}
	}

	componentDidMount() {
		const { currentUserInfo, portalActions } = this.props
		if (currentUserInfo) {
			portalActions.getDoctorListRequest()
		}
	}

	componentDidUpdate(prevProps) {
		const { currentUserInfo, doctorState, portalState } = this.props
		const prevDoctorList = prevProps.portalState.doctorsList
		const currentDoctorList = portalState.doctorsList

		const prevInvitationResponse = prevProps.doctorState.sendEmailInvitationResponse
		const currentInvitationResponse = doctorState.sendEmailInvitationResponse

		if (prevDoctorList !== currentDoctorList) {
			if (currentDoctorList) {
				let ownerDoctor = null
				currentDoctorList.forEach(item => {
					if (item.id === currentUserInfo.attributes.email) {
						ownerDoctor = item
					}
				})
				if (ownerDoctor === null) {
					this.setState({ ownerDoctor: false })
				} else {
					this.setState({ ownerDoctor: true })
				}
			}
		}

		if (prevInvitationResponse.uuid !== currentInvitationResponse.uuid) {
			// TODO Show Blue Rectangle success message
			this.setState({ name: '', email: '' })
			this.setState({ send200: true })
			setTimeout(() => {
				this.setState({ send200: false }, () => {
					this.setState({ showHiddenForm: false })
				})
			}, 3000)
		}
	}

	handleNext = e => {
		e.preventDefault()
		const { nextStep } = this.props
		nextStep()
	}

	handleScrollToForm = () => {
		const { showHiddenForm } = this.state
		//if (!showHiddenForm) { this.setState({ showHiddenForm: true }); }
		this.setState({ name: '', email: '' })
		this.setState({ showHiddenForm: !showHiddenForm })
		setTimeout(() => this.scrollToMyRef(this.state.showHiddenForm), 500)
	}

	scrollToMyRef = status => {
		var element = status ? document.getElementById('hiddenBox') : document.getElementById('visibleBox')
		element.scrollIntoView({ behavior: 'smooth', inline: 'nearest' })
	}

	sendEmailInvitationSuccessBox = () => {
		const { classes } = this.props
		return (
			<div className={classes.hiddenSuccessBox}>
				<div className={classes.hiddenSuccessText}>
					<span>Your Invitation is Successfully Sent!</span>
				</div>
				<div>
					<CheckCircleOutlineRounded className={classes.hiddenSuccessIcon} />
				</div>
			</div>
		)
	}

	doctorRegistrationView = () => {
		const { classes } = this.props
		return (
			<Grid item xs={12} className={classes.addDoctorOption}>
				<Box component='div' className={classes.rowBox}>
					<div className={classes.boldHeadingText}>Before you start</div>
				</Box>
				<Box component='div' className={classes.rowBox}>
					<div className={classes.ListBoxText}>
						<span className={classes.ListBoxHeadingText}>
							You will need the following to proceed
						</span>
						<span>
							<FiberManualRecord className={classes.ListBoxIcon} /> Ayurvedic Registration No.
						</span>
						<span>
							<FiberManualRecord className={classes.ListBoxIcon} /> National Identity Card
						</span>
					</div>
				</Box>
				<Box component='div' className={classes.rowBox}>
					<div className={classes.optionButtonBox}>
						<Widgets.ButtonBox
							type='button'
							disabled={false}
							label='Start Now'
							variant='contained'
							color='primary'
							size='large'
							onClick={this.handleNext}
						/>
					</div>
				</Box>
			</Grid>
		)
	}

	doctorRegisteredView = () => {
		const { classes } = this.props
		return (
			<Grid item xs={12} className={classes.addDoctorOption}>
				<Box component='div' className={classes.rowBox}>
					<div className={classes.boldHeadingText}>You already registered as a doctor</div>
				</Box>
			</Grid>
		)
	}

	handleInputChange = e => {
		const { name, value } = e.target
		this.setState(
			{
				[name]: value
			},
			() => console.log('')
		)
	}

	validateEmail = email => {
		if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
			return false
		} else {
			return true
		}
	}

	sendInvitation = e => {
		e.preventDefault()
		const { name, email } = this.state
		const { currentUserInfo, doctorActions } = this.props
		const host = window.location.protocol + '//' + window.location.host
		const dispensary_mail = base64.encode(currentUserInfo.attributes.email)
		let payload = {
			dispensary_id: dispensary_mail,
			doctor_id: email,
			doctor_name: name,
			callback_url: host + '/#/doctor/signup'
		}
		doctorActions.sendEmailInvitation(payload)
	}

	sendEmailInvitationFormBox = () => {
		const { classes, mediaQuery } = this.props
		const { name, email } = this.state
		const enabled = name.length > 0 && this.validateEmail(email)
		return (
			<FormControlsLayout onSubmit={this.sendInvitation}>
				<Box className={classes.hiddenFormBox}>
					<Box
						className={classes.hiddenFormExitButton}
						component='div'
						onClick={() => this.handleScrollToForm()}>
						<Close />
					</Box>
					<Grid container spacing={0} justifyContent='flex-start'>
						<Grid item xs={12} sm={10} md={10} lg={10}>
							<Controls.InputText
								label='Doctor’s Name'
								type='text'
								name='name'
								value={name}
								onChange={this.handleInputChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<Grid container spacing={2} justifyContent='space-between'>
								<Grid item xs={12} sm={10} md={10} lg={10}>
									<Controls.InputText
										label='Email Address'
										type='email'
										name='email'
										value={email}
										onChange={this.handleInputChange}
									/>
								</Grid>
								<Grid item xs={12} sm={2} md={2} lg={2}>
									<div className={classes.hiddenFormButtonBox}>
										<Widgets.ButtonBox
											type='submit'
											disabled={!enabled}
											label='Send'
											variant='contained'
											color='primary'
											size='large'
										/>
									</div>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</FormControlsLayout>
		)
	}

	render() {
		const { classes, mediaQuery } = this.props
		const { showHiddenForm, send200, ownerDoctor } = this.state
		const isMobile = mediaQuery

		return (
			<Fragment>
				<Box className={classes.visibilityOptionTop} id='visibleBox'>
					<Grid container spacing={0} justifyContent='flex-start'>
						{ownerDoctor ? this.doctorRegisteredView() : this.doctorRegistrationView()}
						{/* section two */}
						<Grid item xs={12} className={classes.sendInviteOption}>
							{isMobile ? (
								<Box>
									<div className={classes.boldHeadingMobileText}>
										Filled by someone else?
									</div>
								</Box>
							) : (
								<Box component='div' className={classes.rowBox}>
									<div className={classes.lightHeadingText}>Alternatively,</div>
									<div className={classes.boldHeadingText}>
										Invite a Doctor to fill the registration form
									</div>
								</Box>
							)}

							<Box component='div' className={classes.rowBox}>
								<div className={classes.optionButtonBox}>
									<Widgets.ButtonBox
										type='button'
										disabled={false}
										label='Send an Invite'
										variantType='outlined'
										color='primary'
										onClick={() => this.handleScrollToForm()}
										size='large'
									/>
								</div>
							</Box>
						</Grid>
					</Grid>
				</Box>
				{/* Hidden Block */}
				{showHiddenForm ? (
					<Box className={classes.hiddenOptionBottom} id='hiddenBox'>
						{send200 === true
							? this.sendEmailInvitationSuccessBox()
							: this.sendEmailInvitationFormBox()}
					</Box>
				) : null}
			</Fragment>
		)
	}
}

function mapStateToProps(state) {
	return {
		doctorState: state.doctorReducer,
		portalState: state.portalReducer,
		currentUserInfo: state.authReducer.currentUserInfo
	}
}

function mapDispatchToProps(dispatch) {
	return {
		doctorActions: bindActionCreators(doctorActions, dispatch),
		portalActions: bindActionCreators(portalActions, dispatch)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles, { withTheme: true })(withMediaQuery('(max-width:425px)')(DoctorOptionWizard)))
