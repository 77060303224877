import React, { Fragment } from 'react'
import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker } from 'react-google-maps'
import Geocode from 'react-geocode'
import { Box, Container, Grid, FormControl, OutlinedInput, TextField } from '@material-ui/core'
import { CancelPresentation } from '@material-ui/icons'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'

/**
 * Google API Keys
 */
Geocode.setApiKey('AIzaSyCzEY7VKqoXdnlj6ThMseRn9dL_W1qLRuY')
Geocode.enableDebug()

const styles = theme => ({
	root: {
		flexGrow: 1
	},

	mapToolBox: {
		backgroundColor: '#f5f5f5',
		display: 'flex',
		padding: '0px 2px',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		color: '#39CFBA',
		fontSize: '14px',
		cursor: 'pointer'
	}
})

class GoogleMapper extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			address: '',
			city: '',
			area: '',
			state: '',
			mapPosition: {
				lat: this.props.center.lat,
				lng: this.props.center.lng
			},
			markerPosition: {
				lat: this.props.center.lat,
				lng: this.props.center.lng
			}
		}
	}

	componentDidMount() {
		const { getLocation } = this.props

		Geocode.fromLatLng(this.state.mapPosition.lat, this.state.mapPosition.lng).then(
			response => {
				const address = response.results[0].formatted_address,
					addressArray = response.results[0].address_components,
					city = this.getCity(addressArray),
					area = this.getArea(addressArray),
					state = this.getState(addressArray)

				let position = {
					lat: this.state.mapPosition.lat,
					lng: this.state.mapPosition.lng
				}

				getLocation(position)
			},
			error => console.log(error)
		)
	}

	componentDidUpdate(prevProps) {
		const { center } = this.props
		if (prevProps.center !== center) {
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (this.state.markerPosition.lat === nextProps.center.lat) {
			return false
		} else {
			return true
		}
	}

	getCity = addressArray => {
		let city = ''
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
				city = addressArray[i].long_name
				return city
			}
		}
	}

	getState = addressArray => {
		let state = ''
		for (let i = 0; i < addressArray.length; i++) {
			for (let i = 0; i < addressArray.length; i++) {
				if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
					state = addressArray[i].long_name
					return state
				}
			}
		}
	}

	getArea = addressArray => {
		let area = ''
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types[0]) {
				for (let j = 0; j < addressArray[i].types.length; j++) {
					if (
						'sublocality_level_1' === addressArray[i].types[j] ||
						'locality' === addressArray[i].types[j]
					) {
						area = addressArray[i].long_name
						console.log('area', area)
						return area
					}
				}
			}
		}
	}

	setArea = area => {
		const { getArea } = this.props
		getArea(area)
	}

	onMarkerDragEnd = event => {
		const { getLocation, updateLocation } = this.props
		let newLat = event.latLng.lat(),
			newLng = event.latLng.lng(),
			addressArray = []

		Geocode.fromLatLng(newLat, newLng).then(
			response => {
				const address = response.results[0].formatted_address,
					addressArray = response.results[0].address_components,
					city = this.getCity(addressArray),
					area = this.getArea(addressArray),
					state = this.getState(addressArray)

				let position = {
					lat: event.latLng.lat(),
					lng: event.latLng.lng()
				}
				this.setArea(area)
				updateLocation(event.latLng.lat(), event.latLng.lng())
				getLocation(position, address)
			},
			error => console.log(error)
		)
	}

	onPlaceSelected = place => {
		const address = place.formatted_address,
			addressArray = place.address_components,
			city = this.getCity(addressArray),
			area = this.getArea(addressArray),
			state = this.getState(addressArray),
			latValue = place.geometry.location.lat(),
			lngValue = place.geometry.location.lng()

		this.setState({
			address: address ? address : '',
			area: area ? area : '',
			city: city ? city : '',
			state: state ? state : '',
			markerPosition: {
				lat: latValue,
				lng: lngValue
			},
			mapPosition: {
				lat: latValue,
				lng: lngValue
			}
		})
	}

	renderMarker = () => {}

	render() {
		let map
		const { classes, theme, history } = this.props

		const AsyncMap = withScriptjs(
			withGoogleMap(props => (
				<GoogleMap
					defaultZoom={this.props.zoom}
					defaultCenter={{ lat: this.props.center.lat, lng: this.props.center.lng }}>
					{/* Marker Point on google map */}
					<Marker
						draggable={true}
						onDragEnd={this.onMarkerDragEnd}
						position={{
							lat: this.props.center.lat,
							lng: this.props.center.lng
						}}
					/>
				</GoogleMap>
			))
		)

		if (this.props.center.lat !== undefined) {
			map = (
				<div>
					<Box className={classes.mapToolBox}>
						<strong>Drag the marker to location</strong>
						<CancelPresentation onClick={this.props.closeMap} />
					</Box>
					<AsyncMap
						googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyCzEY7VKqoXdnlj6ThMseRn9dL_W1qLRuY&libraries=places,geocode'
						loadingElement={<div style={{ height: `100%` }} />}
						containerElement={<div style={{ height: this.props.height }} />}
						mapElement={<div style={{ height: `100%` }} />}
					/>
				</div>
			)
		} else {
			map = <div style={{ height: this.props.height }} />
		}

		return map
	} // End render();
}

export default withStyles(styles, { withTheme: true })(GoogleMapper)
