import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Container, Grid } from '@material-ui/core'
import { CheckCircleOutlineRounded } from '@material-ui/icons'

import { FORM_DISPENSARY, FORM_DOCTOR, RESET_PASSWORD, FORM_DISPENSARY_STAFF } from 'constants/FormConstants'
import { NAVBAR_ROUTES } from 'constants/NavConstants'
import SuccessHeader from '../widgets/SuccessHeader'
import searchImg from 'images/user_search.svg'
import moment from 'moment'

import { useDispatch } from 'react-redux'

import { updateFormStep } from '../../doctor/redux/actions'

var timeInSecond = 172800

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		border: '1px solid #f5f5f5', // 7cdcce
		minHeight: 'calc(100vh - 0.233rem)',
		backgroundColor: '#FEF9E9',

		[theme.breakpoints.down('xs')]: {
			backgroundImage: 'linear-gradient(to bottom, #ebfffb, #effffc, #f3fefd, #f7fefd, #fbfdfd)'
		},
		// ZOOM Level
		[theme.breakpoints.between('md', 'lg')]: {
			zoom: '0.75',
			minHeight: `calc(100vh + calc(100vh * .33))`
		}
	},
	layoutOverlay: {
		backgroundColor: '#FFFFFF',
		margin: '0',
		padding: '0',
		marginTop: '-4rem'
	},
	successIcon: {
		color: '#39CFBA',
		fontSize: 'calc(100% + 8.5rem)',
		[theme.breakpoints.down('xs')]: {
			fontSize: '6rem'
		}
	},
	successContent: {
		textAlign: 'center'
	},

	primaryHeading: {
		// border: '1px dotted #7cdcce',
		fontFamily: 'Roboto Condensed,sans-serif',
		//fontSize: 'clamp(30px,5vw,50px)',
		fontSize: 'clamp(30px,calc(1vw + 1.5rem),50px)',
		color: '#0E2C39'
	},

	secondaryHeading: {
		fontFamily: 'Roboto Medium,sans-serif',
		//fontSize: 'clamp(20px,4vw,40px)',
		fontSize: 'clamp(20px,calc(1vw + 1rem),40px)',
		color: '#0E2C39',
		padding: '5rem 0rem',
		[theme.breakpoints.down('sm')]: {
			padding: '6rem 0rem'
		}
	},
	secondaryHeadingDoc: {
		fontFamily: 'Roboto Medium,sans-serif',
		fontSize: 'clamp(18px,3.2vw,30px)',
		color: '#0E2C39',
		padding: '3rem 0rem',
		lineHeight: '4rem',
		[theme.breakpoints.down('xs')]: {
			fontFamily: 'Roboto Light,sans-serif',
			padding: '2rem 0rem',
			lineHeight: '2rem'
		}
	},
	footerBox: {
		// border: '1px dotted #7cdcce',
		fontFamily: 'Roboto Light,sans-serif',
		fontSize: 'clamp(18px,2vw,30px)',
		color: '#0E2C39',
		marginTop: '-5%'
	},

	timeBox: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	timeBoxElement: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center'
	},
	loginLink: {
		fontFamily: 'Roboto Medium,sans-serif',
		fontSize: 'clamp(15px,2vw,20px)',
		color: '#0E2C39',
		cursor: 'pointer',
		textDecoration: 'none',

		[theme.breakpoints.down('xs')]: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-start',
			alignItems: 'flex-start',
			textAlign: 'left',
			fontSize: '15px'
		}
	},

	loginLinkGreen: {
		color: '#39CFBA',
		[theme.breakpoints.down('xs')]: {
			fontFamily: 'Roboto Bold,sans-serif',
			fontSize: '18px',
			textAlign: 'left',
			padding: '1em 0em'
		}
	}
}))

const SuccessWizardLayout = props => {
	const [seconds, setSecond] = useState(172800)
	const [secondsLeft, setSecondsLeft] = useState(172800)
	const classes = useStyles()
	const { formType, isLogo = false, username = null, history, exitForm } = props
	const [endDate, setEndDate] = useState(moment().add(2, 'd').toDate())
	const hist = useHistory()

	const dispatch = useDispatch()

	useEffect(() => {
		if (formType === FORM_DISPENSARY) {
			setTimeout(() => {
				history.push(NAVBAR_ROUTES.DOCTOR_SIGNUP_FORM)
			}, 3000)
		}

		if (formType === FORM_DISPENSARY_STAFF) {
			setTimeout(() => {
				history.push(NAVBAR_ROUTES.OWNER_PORTAL_WEB)
				history.go()
			}, 3000)
		}
	})

	useEffect(() => {
		const interval = setInterval(() => {
			if (secondsLeft == 0) {
				clearInterval(interval)
			} else {
				setSecondsLeft(secondsLeft - 1)
			}
		}, 1000)
		return () => clearInterval(interval)
	})

	// function calculateTimeLeft() {
	//     // var date = moment().add(2, 'd').toDate();

	//     var currentDate = moment().toDate();
	//     console.log(endDate, currentDate)
	//     const difference = +new Date(moment(endDate).format('YYYY-MM-DD')) - +new Date(currentDate);
	//     let timeLeft = {};

	//     if (difference > 0) {
	//         timeLeft = {
	//             days: ("0" + Math.floor(difference / (1000 * 60 * 60 * 24))).slice(-2),
	//             hours: ("0" + Math.floor((difference / (1000 * 60 * 60)) % 24)).slice(-2),
	//             minutes: ("0" + Math.floor((difference / 1000 / 60) % 60)).slice(-2),
	//             seconds: ("0" + Math.floor((difference / 1000) % 60)).slice(-2)
	//         };
	//     }

	//     return timeLeft;
	// }

	// const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft());

	// React.useEffect(() => {
	//     const id = setTimeout(() => {
	//         // setTimeLeft(calculateTimeLeft());
	//         // convertToHms(calculateTimeLeft())
	//     }, 1000);

	//     return () => {
	//         clearTimeout(id);
	//     };
	// });

	function convertToHms() {
		const days = ('0' + Math.floor(secondsLeft / 24 / 60 / 60)).slice(-2)
		const hoursLeft = Math.floor(secondsLeft - days * 86400)
		const hours = ('0' + Math.floor(hoursLeft / 3600)).slice(-2)
		const minutesLeft = Math.floor(hoursLeft - hours * 3600)
		const minutes = ('0' + Math.floor(minutesLeft / 60)).slice(-2)
		const seconds = ('0' + (secondsLeft % 60)).slice(-2)

		return (
			<Box component='div' className={classes.timeBox}>
				<Box component='span' className={classes.timeBoxElement}>
					<span className='type'>days</span>
					<span className='value'>
						{days} {' : '}
					</span>
				</Box>
				<Box component='span' className={classes.timeBoxElement}>
					<span className='type'>hrs</span>
					<span className='value'>
						{hours} {' : '}
					</span>
				</Box>
				<Box component='span' className={classes.timeBoxElement}>
					<span className='type'>min</span>
					<span className='value'>
						{minutes} {' : '}
					</span>
				</Box>
				<Box component='span' className={classes.timeBoxElement}>
					<span className='type'>sec</span>
					<span className='value'>{seconds}</span>
				</Box>
			</Box>
		)
	}

	function navigateToDispensaryOwnerPortal() {
		hist.push(NAVBAR_ROUTES.OWNER_PORTAL_WEB)
		dispatch(updateFormStep(1))
	}

	function doctorSuccessBox() {
		return (
			<Grid container spacing={10} justifyContent='center' className={classes.successContent}>
				<Grid item xs={10}>
					<div className={classes.primaryHeading}>Thank You!</div>
					<div className={classes.secondaryHeadingDoc}>
						We are checking your information.
						<br /> We’ll get back to you within
					</div>
					<div className='timer'>{convertToHms()} </div>

					<div className='search-icon'>
						<img className='search-user-img' src={searchImg} alt='search-user' />
					</div>
				</Grid>
				<Grid item xs={10}>
					<div className={classes.footerBox}>
						<div className={classes.loginLink}>
							<span>Add more doctors? or Check registration status? </span>
							<span
								className={classes.loginLinkGreen}
								onClick={() => navigateToDispensaryOwnerPortal()}>
								Login to Dispensary Owner Portal
							</span>
						</div>
					</div>
				</Grid>
			</Grid>
		)
	}

	function dispensarySuccessBox() {
		setTimeout(() => {
			history.push(NAVBAR_ROUTES.DOCTOR_SIGNUP_FORM)
		}, 3000)
		return (
			<Grid container spacing={10} justifyContent='center' className={classes.successContent}>
				<Grid item xs={10}>
					<div className={classes.primaryHeading}>Dispensary Registered</div>
					<div className={classes.secondaryHeading}>
						Your Dispensary is Successfully Registered!
					</div>
					<div>
						<CheckCircleOutlineRounded className={classes.successIcon} />
					</div>
				</Grid>
				<Grid item xs={10}>
					<div className={classes.footerBox}>Continue the Registration ...</div>
				</Grid>
			</Grid>
		)
	}

	function staffSuccessBox() {
		return (
			<Grid container spacing={10} justifyContent='center' className={classes.successContent}>
				<Grid item xs={10}>
					<div className={classes.primaryHeading}>Staff Registered</div>
					<div className={classes.secondaryHeading}>
						Your Staff member is Successfully Registered!
					</div>
					<div>
						<CheckCircleOutlineRounded className={classes.successIcon} />
					</div>
				</Grid>
				<Grid item xs={10}>
					<div className={classes.footerBox}>Continue to Portal ...</div>
				</Grid>
			</Grid>
		)
	}

	function resetPasswordSuccessBox() {
		return (
			<Grid container spacing={10} justifyContent='center' className={classes.successContent}>
				<Grid item xs={10}>
					<div className={classes.primaryHeading}>Change Password</div>
					<div className={classes.secondaryHeading}>
						Your New Password has been saved Successfully!
					</div>
					<div>
						<CheckCircleOutlineRounded className={classes.successIcon} />
					</div>
				</Grid>
			</Grid>
		)
	}

	switch (formType) {
		case FORM_DISPENSARY:
			return (
				<Box component='div' className={classes.layoutOverlay}>
					<Container className={classes.root} maxWidth='md' disableGutters={true} component='div'>
						<SuccessHeader showLogo={isLogo} username={username} />
						{dispensarySuccessBox()}
					</Container>
				</Box>
			)

		case FORM_DOCTOR:
			return (
				<Box component='div' className={classes.layoutOverlay}>
					<Container className={classes.root} maxWidth='md' disableGutters={true} component='div'>
						<SuccessHeader showLogo={isLogo} username={username} exitForm={exitForm} />
						{doctorSuccessBox()}
					</Container>
				</Box>
			)

		case RESET_PASSWORD:
			return (
				<Box component='div' className={classes.layoutOverlay}>
					<Container className={classes.root} maxWidth='md' disableGutters={true} component='div'>
						<SuccessHeader showLogo={isLogo} username={username} />
						{resetPasswordSuccessBox()}
					</Container>
				</Box>
			)
		case FORM_DISPENSARY_STAFF:
			return (
				<Box component='div' className={classes.layoutOverlay}>
					<Container className={classes.root} maxWidth='md' disableGutters={true} component='div'>
						<SuccessHeader showLogo={isLogo} username={username} />
						{staffSuccessBox()}
					</Container>
				</Box>
			)

		default:
			return <Box>Error</Box>
	}
}

export default SuccessWizardLayout
