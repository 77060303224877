import React, { useState, useEffect, Fragment } from 'react'
import { Box, Container } from '@material-ui/core'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import { ArrowBack, Close } from '@material-ui/icons'
import brandLogo from 'images/wedawaru_logo_lg.png'
import { NAVBAR_ROUTES } from 'constants/NavConstants'

import { useHistory } from 'react-router'

const useStyles = makeStyles(theme => ({
	optToolBox: {
		boxSizing: 'border-box',
		flexGrow: 1,
		display: 'grid',
		gridTemplateColumns: '1fr auto',
		padding: '1rem 0.5rem',
		[theme.breakpoints.down('xs')]: {
			borderBottom: '1px solid #ddd'
		}
	},
	brandLogoIcon: {
		width: 'auto',
		height: '42px',
		marginLeft: '1em',
		[theme.breakpoints.down('xs')]: {
			//flexGrow: 1,
		}
	},
	toolBoxLeft: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		color: '#0E2C39',
		fontFamily: 'Roboto Condensed,sans-serif',
		fontSize: 'clamp(24px,5vw,50px)'
	},
	toolBoxRight: {
		marginRight: theme.spacing(0),
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'flex-start',
		cursor: 'pointer',
		color: '#39CFBA',
		marginTop: theme.spacing(-2),
		marginRight: theme.spacing(-1),
		'&:hover': {
			color: '#159782'
		}
	}
}))

const OTPFormHeader = props => {
	const history = useHistory()
	const classes = useStyles()
	const theme = useTheme()
	const { heading = null, formType } = props

	const onPressPressedClose = () => {
		// alert('onPressed close')
		if (formType === 'FORM_DISPENSARY') {
			history.push(NAVBAR_ROUTES.HOME)
		}

		if (formType === 'RESET_PASSWORD') {
			history.push(NAVBAR_ROUTES.OWNER_LOGIN)
		}

		if (formType === 'FORM_DISPENSARY_STAFF') {
			history.push(NAVBAR_ROUTES.OWNER_PORTAL_WEB)
		}
	}

	return (
		<Box component='div'>
			<Box className={classes.optToolBox}>
				<div className={classes.toolBoxLeft}>{heading}</div>
				<div className={classes.toolBoxRight}>
					<span onClick={onPressPressedClose}>
						<Close />
					</span>
				</div>
			</Box>
		</Box>
	)
}

export default OTPFormHeader
