import React, { Component, Fragment, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import {
    Box,
    Container,
    Toolbar,
    AppBar, Typography,
    Button
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import brandLogo from '../../../images/wedawaru_logo_lg.png'
import Controls from 'containers/forms/components/controls'
import Widgets from 'components/widgets'
import * as kioskActions from './redux/actions';
import * as loginActions from './../auths/redux/actions'
// import { APIStatusCode, AppConstants } from '../../../constants';
import PropTypes from 'prop-types';
import FormControlsLayout from 'containers/forms/components/FormControlsLayout'
import FormControlEnd from 'containers/forms/components/FormControlEnd'
import { AppointmentsConstants } from 'constants/AppointmentsConstants'
import appointmentsReducer from './redux/reducer'
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import * as subscriptions from '../../../graphql/subscriptions'
// import Moment from 'moment';
import moment from 'moment-timezone'
import DefaultKeyboard from 'containers/forms/components/shared/DefaultKeyboard'
import DefaultNumberKeyboard from 'containers/forms/components/shared/DefaultNumberKeyboard'

const styles = theme => ({
    root: {
        flexGrow: 1,

        [theme.breakpoints.up('lg')]: {
            maxWidth: '1300px'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        // TODO zoom
        [theme.breakpoints.between('md', 'lg')]: {
            //backgroundColor:'red',
            zoom: '0.87'
        },

    },

    navbarStyle: {
        background: '#006837',
        color: '#0e2c39'
    },
    brandLogoIcon: {
        width: 'auto',
        height: '50px',
        marginLeft: '-1em',
        [theme.breakpoints.down('sm')]: {
            //flexGrow: 1,
        }
    },
    brandLogoIcon2: {
        width: '100px',
        height: '100px',
        marginLeft: '-1em',
        [theme.breakpoints.down('sm')]: {
            //flexGrow: 1,
        }
    },
    title: {
        fontSize: 35,
        color: '#ffffff',
        fontWeight: 'bold'
    },
    headerOptions: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-evenly'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center'
    },
    container: {
        marginTop: theme.spacing(5)
    },
    containerThankyou: {
        marginTop: theme.spacing(10)
    },
    gridSpacing: {
        marginTop: theme.spacing(5)
    },
    formLabelWrapper: {
        textAlign: 'right',
    },
    formLabel: {
        fontSize: 25,
        fontWeight: 'bold',
        lineHeight: '25px',
    },
    formLabelThanks: {
        fontSize: 30,
        fontWeight: 'bold',
        lineHeight: '30px',
    },
    textFiled: {
        width: '100%',
        border: '2px solid #159782',
        color: 'black',
        '& .MuiOutlinedInput-root': {
            borderRadius: '0px',
            '& .MuiOutlinedInput-input': {
                fontFamily: 'Roboto Medium,sans-serif',
                //fontSize: 'clamp(13px,2vw,20px)'
            },

            '& fieldset': {
                borderColor: 'none',
                border: 'none'
            },
            '&:hover fieldset': {
                //borderColor: themeGreenColor,
                border: 'none'
            },
            '&.Mui-focused fieldset': {
                //borderColor: mingColor, * when focus feild
                borderColor: 'none',
                border: 'none'
            }
        },
        "&::placeholder": {
            textOverflow: "ellipsis !important",
            color: "blue"
        }
    },
    numberWrapper: {
        background: '#0E2C39',
        width: 'auto',
        height: 150,
        borderRadius: 10,
        color: '#ffffff',
        textAlign: 'center',
        fontSize: 100,
    },
    nextButton: {
        width: 150,
        height: 50,
        fontSize: 25,
    },
    closeButton: {
        background: '#707070',
        color: '#ffffff',
        fontSize: 25,
        textTransform: 'capitalize'
    },
    countTimer: {
        fontWeight: '400',
        fontSize: '15px',
        marginLeft: '5px',
    },
    kioskFooter: {
        background: '#0E2C39',
        minHeight: 60,
        textAlign: 'center',
        color: '#ffffff',
        fontWeight: '500',
        position: 'fixed',
        width: '100%',
        bottom: 0,
        padding: '7px 0'
    },
    footerText: {
        fontSize: 30,
    },
    container: {
        maxWidth: 1380
    },
})

class KioskDetailsForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            firstName: '',
            mobileNumber: '07',
            slotAvailable: this.props.appointmentState.appointments.length == 0 ? false : true
        }

    }


    componentDidMount() {
        if (this.props.appointmentState.appointments.length == 0) {
            this.getDoctorsByDispensary(this.props.loginState.currentUserInfo);
        }

    }

    getDoctorsByDispensary = (payload) => {
        this.props.appointmentsActions.requestDoctorsByDispensary(payload);
    };

    refreshTodayApoimentsForDispencery = (payload) => {

        this.props.appointmentsActions.requestAppointments(payload);
    }

    handleInputChange = e => {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    CHARACTER_LIMIT = 10;

    render() {

        const { classes, history } = this.props;
        const { firstName, mobileNumber, slotAvailable, mobileNumberValidationError } = this.state;

        return (
            <>
                <AppBar position='fixed' className={classes.navbarStyle}>
                    <Container maxWidth='lg' disableGutters={false}>
                        <Toolbar className={classes.toolbar}>
                            <Box component='span'>
                                <img src={brandLogo} className={classes.brandLogoIcon} alt='Logo' />
                            </Box>
                            <div className={classes.headerOptions}>
                                <Box>
                                    <Typography className={classes.title}>Get Your Number</Typography>
                                </Box>
                                <Box>
                                    <Typography className={classes.title}>ඔබගේ අංකය ලබාගන්න</Typography>
                                </Box>
                            </div>
                        </Toolbar>
                    </Container>
                </AppBar>

                <Container className={classes.container}>
                    <Box
                        component='div'
                        flexDirection='column'
                        justifyContent='center'
                        alignItems='center'
                        className={classes.contactFormBox}>

                        <FormControlsLayout>
                            <Grid container>
                            
                            <Grid item xs={12} className={classes.gridSpacing}>
                                <DefaultKeyboard onClickEnter={this.props.appointmentsActions.navigateToKioskMobile}/>
                            </Grid>

                            </Grid>
                        </FormControlsLayout>
                    </Box>
                </Container>

                {/* <Box className={classes.kioskFooter}>
                    <Typography className={classes.footerText}>Medical Center - Dr U P Daniel</Typography>
                </Box> */}
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        appointmentState: state.appointmentsReducer,
        loginState: state.authReducer,
        dispensaryState: state.dispensaryReducer

    }
}

function mapDispatchToProps(dispatch) {
    return {
        appointmentsActions: bindActionCreators(kioskActions, dispatch),
        loginActions: bindActionCreators(loginActions, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(KioskDetailsForm))
