import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Box, Grid } from '@material-ui/core'

import * as doctorActions from './redux/actions'
import * as authActions from '../auths/redux/actions'
import FormControlsLayout from 'containers/forms/components/FormControlsLayout'
import Controls from 'containers/forms/components/controls'
import Helpers from 'utils/Helpers'
import Validators from 'utils/Validators'
import FormControlEnd from 'containers/forms/components/FormControlEnd'

// spacing = 1 (8px)
// rem = 1  (16px)
const styles = theme => ({
	root: {
		flexGrow: 1
	},

	leftBox: {
		// border: '1px dotted blue',
		//borderRight: '1px solid #ddd',
		marginTop: theme.spacing(3),
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'flex-start'
	},

	rightBox: {
		// border: '1px dotted blue',
		marginTop: theme.spacing(3),
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'flex-start' // TODO
	},

	loginBoxHeading: {
		fontFamily: 'Roboto Bold,sans-serif',
		fontSize: '24px',
		color: '#0E2C39',
		paddingBottom: `${theme.spacing(2)}px`,
		marginTop: `${theme.spacing(-2)}px`
	},
	numberInput: {
		'& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
			display: 'none'
		}
	}
})

class DoctorAddForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			errors: {},
			titles:  [
					{ title: 'Rev.', value: 'Rev.' },
					{ title: 'Dr.', value: 'Dr.' },
					{ title: 'Prof.', value: 'Prof.' },
					{ title: 'Mrs.', value: 'Mrs.' },
					{ title: 'Mr.', value: 'Mr.' },
					{ title: 'Ms.', value: 'Ms.' }
				].sort((a,b) => (a.title > b.title)? 1 : -1)
			,
			specialities: [
				{ title: 'Toxicology', value: 'Toxicology' },
				{ title: 'Cardiologist', value: 'Cardiologist' },
				{ title: 'Orthopedic', value: 'Orthopedic' },
				{ title: 'Dermatologist', value: 'Dermatologist' },
				{ title: 'Hydrophobia', value: 'Hydrophobia' },
				{ title: 'General Physicians', value: 'General Physicians' },
				{ title: 'Renal Diseases', value: 'Renal Diseases' },
				{ title: 'Burns', value: 'Burns' },
				{ title: 'Obstetrician Gynecologist', value: 'Obstetrician Gynecologist' },
				{ title: 'Ophthalmologist', value: 'Ophthalmologist' },
				{ title: 'Diabetes', value: 'Diabetes' },
				{ title: 'Pediatrician', value: 'Pediatrician' },
				{ title: 'Psychiatrist', value: 'Psychiatrist' },
				{ title: 'Asthma and Wheezing', value: 'Asthma and Wheezing' },
			].sort((a,b) => (a.title > b.title)? 1 : -1),
			treatment_methods: [
				{ title: 'Traditional', value: 'Traditional' },
				{ title: 'Ayurvedic', value: 'Ayurvedic' }
			].sort((a,b) => (a.title > b.title)? 1 : -1)
		}
	}

	componentDidMount() {
		// this.props.doctorActions.getDoctorMetadata()
	}

	componentDidUpdate(prevProps) {
		const {
			doctorMetaData,
			addNewDoctorResponse,
			assignDoctorToDispensaryResponse,
			owner,
			currentUserInfo,
			doctorActions
		} = this.props

		if (prevProps.doctorMetaData !== doctorMetaData) {
			this.setState({ specialities: doctorMetaData.specialities }, () => console.log(this.state))
			this.setState({ titles: doctorMetaData.titles }, () => console.log(''))
		}

		if (prevProps.addNewDoctorResponse.uuid !== addNewDoctorResponse.uuid) {
			if (owner) {
				const payload = {
					dispensary: currentUserInfo.attributes.email,
					doctor: currentUserInfo.attributes.email
				}
				doctorActions.assignDoctorToDispensaryRequest(payload)
			}
		}

		if (prevProps.assignDoctorToDispensaryResponse.uuid !== assignDoctorToDispensaryResponse.uuid) {
			if (owner) {
				const { navigateToNicUploadWizard } = this.props
				navigateToNicUploadWizard()
			} else {
				const { navigateToNicUploadWizard } = this.props
				navigateToNicUploadWizard()
			}
		}
	}

	validator = () => {
		const { data, owner } = this.props
		const { errors } = this.state
		const {
			doctorTitle,
			initials,
			lastName,
			displayName,
			displayNameInSinhala,
			SLMCRegNo,
			specility,
			treatment_method,
			doctorFee,
			education,
			mobile,
			email,
			emailConfirm,
			password
		} = data

		errors.doctorTitle = Validators.required(doctorTitle)
		errors.initials = Validators.required(initials) || Validators.minLength(initials, 1)
		errors.lastName = Validators.required(lastName) || Validators.minLength(lastName, 3)
		errors.displayName = Validators.required(displayName) || Validators.minLength(displayName, 3)
		errors.displayNameInSinhala = Validators.required(displayNameInSinhala) || Validators.minLength(displayNameInSinhala, 3)
		errors.SLMCRegNo = Validators.required(SLMCRegNo) || Validators.minLength(SLMCRegNo, 3)
		errors.specility = Validators.required(specility) || Validators.minLength(specility, 3)
		errors.treatment_method = Validators.required(treatment_method) || Validators.minLength(treatment_method, 3)
		errors.doctorFee = Validators.minLength(doctorFee, 1)
		errors.education = Validators.required(education) || Validators.minLength(education, 2)

		if (!owner) {
			errors.mobile = Validators.required(mobile) || Validators.minLength(mobile, 10)
			errors.email = Validators.validateEmail(email)
			errors.emailConfirm = Validators.compareToEmail(emailConfirm, email)
			errors.password = Validators.minLength(password, 8)
		}

		this.setState(prevState => ({
			errors: errors
		}))

		return Object.values(errors).every(err => err === '' || err === undefined)
	}

	handleNext = e => {
		const { nextStep } = this.props
		e.preventDefault()
		// nextStep();
		if (this.validator()) {
			// nextStep();
		}
	}

	createDoctorRequest = e => {
		const { owner, update, data, currentUserInfo, doctorActions, authActions } = this.props
		e.preventDefault()
		if (this.validator()) {
			if (owner) {
				if (update) {
					const payload = {
						register: false,
						initials: data.doctorTitle,
						first_name: data.initials,
						last_name: data.lastName,
						display_name: data.displayName,
						display_name_sinhala:data.displayNameInSinhala,
						slmc_registratoin_number: data.SLMCRegNo,
						speciality: data.specility,
						treatment_method: data.treatment_method,
						consultation_fee: parseInt(data.doctorFee),
						educations: data.education,
						mobile_number: data.email,
						email: data.email,
						id: data.email,
						image_url: data.image_url,
						verified: data.verified,
						city: 'Colombo'
					}
					doctorActions.updateDoctorInfoApi(payload)
				} else {
					const payload = {
						register: true,
						initials: data.doctorTitle,
						first_name: data.initials,
						last_name: data.lastName,
						display_name: data.displayName,
						display_name_sinhala:data.displayNameInSinhala,
						slmc_registratoin_number: data.SLMCRegNo,
						speciality: data.specility,
						treatment_method: data.treatment_method,
						consultation_fee: parseInt(data.doctorFee),
						educations: data.education,
						mobile_number: currentUserInfo.attributes.email,
						city: 'Colombo'
					}
					doctorActions.submitOwnerDoctorToApi(payload)
				}
			} else {
				const awsPayload = {
					from: 'CREATE_DOCTOR_FROM_INVITATION',
					payload: {
						username: data.email,
						password: data.password,
						attributes: {
							given_name: data.initials,
							family_name: data.lastName,
							email: data.email,
							'custom:role': 'doctor'
						}
					}
				}
				const payload = {
					register: true,
					initials: data.doctorTitle,
					first_name: data.initials,
					last_name: data.lastName,
					display_name: data.displayName,
					display_name_sinhala:data.displayNameInSinhala,
					slmc_registratoin_number: data.SLMCRegNo,
					speciality: data.specility,
					treatment_method: data.treatment_method,
					consultation_fee: parseInt(data.doctorFee),
					educations: data.education,
					mobile_number: data.email,
					city: 'Colombo',
					contact_number: data.mobile
				}
				doctorActions.awsDoctorRegistration(awsPayload)
				authActions.setTempDoctorPayload(payload)

			}
		}
	}

	render() {
		const {
			conditionTerms,
			handleTermsAndConditions,
			data,
			handleInputChange,
			classes,
			owner,
			update
		} = this.props
		const {
			doctorTitle,
			initials,
			lastName,
			displayName,
			displayNameInSinhala,
			SLMCRegNo,
			specility,
			treatment_method,
			doctorFee,
			education,
			mobile,
			email,
			emailConfirm,
			password
		} = data
		const { errors, titles, specialities, treatment_methods } = this.state

		let _titles = []
		titles.forEach(text => {
			_titles.push({
				value: text,
				title: text
			})
		})

		let _specialities = []
		specialities.forEach(text => {
			_specialities.push({
				value: text,
				title: text
			})
		})

		let _treatment_methods = []
		treatment_methods.forEach(text => {
			_treatment_methods.push({
				value: text,
				title: text
			})
		})

		return (
			<FormControlsLayout>
				<Grid container spacing={0} justifyContent='space-between'>
					<Grid item xs={12} sm={5} md={5} lg={5} className={classes.leftBox}>
						<Grid container spacing={0} justifyContent='space-between'>
							<Grid container spacing={0} justifyContent='space-between'>
								<Grid item xs={12} sm={5} md={5} lg={5}>
									<Controls.Select
										label='Title'
										name='doctorTitle'
										lblWidth={30}
										value={doctorTitle}
										onChange={handleInputChange}
										error={errors.doctorTitle}
										option={titles}
									/>
								</Grid>
								<Grid item xs={12} sm={5} md={5} lg={5}>
									<Controls.InputText
										label='Initials'
										type='text'
										name='initials'
										limit={10}
										value={initials}
										onChange={handleInputChange}
										error={errors.initials}
									/>
								</Grid>
								{/*  */}
							</Grid>
							{/* last name */}
							<Grid item xs={12}>
								<Controls.InputText
									label='Last Name'
									type='text'
									name='lastName'
									value={lastName}
									onChange={handleInputChange}
									error={errors.lastName}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controls.InputText
									label='Display Name (e.g. Dr. Name)'
									type='text'
									limit={26}
									name='displayName'
									value={displayName}
									onChange={handleInputChange}
									error={errors.displayName}
									charLeft={Helpers.feildTextLeft('displayName', displayName.length)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controls.InputText
									label='Display Name in Sinhala (e.g. වෛද්‍ය. නම)'
									type='text'
									limit={50}
									name='displayNameInSinhala'
									value={displayNameInSinhala}
									onChange={handleInputChange}
									error={errors.displayNameInSinhala}
									// charLeft={Helpers.feildTextLeft('displayNameInSinhala', displayNameInSinhala.length)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controls.InputText
									label='Ayurvedic Registration No.'
									type='text'
									name='SLMCRegNo'
									value={SLMCRegNo}
									onChange={handleInputChange}
									error={errors.SLMCRegNo}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controls.Select
									label='Speciality'
									name='specility'
									value={specility}
									lblWidth={80}
									onChange={handleInputChange}
									error={errors.specility}
									option={specialities}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controls.Select
									label='Treatment method'
									name='treatment_method'
									value={treatment_method}
									lblWidth={80}
									onChange={handleInputChange}
									error={errors.treatment_method}
									option={treatment_methods}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controls.InputText
									label='Doctor Fee per visit'
									type='number'
									name='doctorFee'
									value={doctorFee}
									endAdornmentText='SL Rs/='
									onChange={handleInputChange}
									error={errors.doctorFee}
								/>
							</Grid>
						</Grid>
					</Grid>

					{/* _____Right half _______*/}

					<Grid item xs={12} sm={5} md={5} lg={5} className={classes.rightBox}>
						<Grid container spacing={0} justifyContent='space-between'>
							<Grid item xs={12}>
								<Controls.MuiltInputBox
									label='Education Qualifications (e.g.BASM)'
									value={education}
									name='education'
									limit={250}
									onChange={handleInputChange}
									error={errors.education}
									charLeft={Helpers.feildTextLeft('education', education.length)}
								/>
							</Grid>

							{/* ::Login block*/}
							{owner ? null : (
								<>
									<Box component='div' className={classes.loginBoxHeading}>
										Create a login
									</Box>

									<Grid item xs={12}>
										<Controls.InputText
											label='Mobile No.'
											type='text'
											name='mobile'
											value={mobile}
											onChange={handleInputChange}
											error={errors.mobile}
										/>
									</Grid>

									<Grid item xs={12}>
										<Controls.InputText
											label='Email Address'
											type='email'
											name='email'
											value={email}
											onChange={handleInputChange}
											error={errors.email}
										/>
									</Grid>
									<Grid item xs={12}>
										<Controls.InputText
											label='Confirm the Email Address'
											type='email'
											name='emailConfirm'
											value={emailConfirm}
											onChange={handleInputChange}
											error={errors.emailConfirm}
										/>
									</Grid>
									<Grid item xs={12}>
										<Controls.InputPasswordText
											label='Password'
											type='password'
											name='password'
											value={password}
											onChange={handleInputChange}
											error={errors.password}
										/>
									</Grid>
								</>
							)}
						</Grid>
						{/* END */}
					</Grid>
				</Grid>
				<FormControlEnd
					btnType='button'
					label={update ? 'Update' : 'Next'}
					isCheckbox={true}
					disabled={!conditionTerms}
					onChange={handleTermsAndConditions}
					onPressed={this.createDoctorRequest}
				/>
			</FormControlsLayout>
		)
	}
}

function mapStateToProps(state) {
	return {
		tempDoctor: state.authReducer.tempDoctor,
		tempDispensary: state.authReducer.tempDispensary,
		currentUserInfo: state.authReducer.currentUserInfo,
		createDoctorResponse: state.doctorReducer.createDoctorResponse,
		doctorMetaData: state.doctorReducer.doctorMetaData,
		addNewDoctorResponse: state.doctorReducer.addNewDoctorResponse,
		assignDoctorToDispensaryResponse: state.doctorReducer.assignDoctorToDispensaryResponse,
		invitationDoctorResponse: state.doctorReducer.invitationDoctorResponse
	}
}

function mapDispatchToProps(dispatch) {
	return {
		doctorActions: bindActionCreators(doctorActions, dispatch),
		authActions: bindActionCreators(authActions, dispatch)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles, { withTheme: true })(DoctorAddForm))
